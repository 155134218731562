/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Select } from 'antd';
import { Link } from 'react-router-dom';

const { Option } = Select;

export const instructionsColumns = (handleDelete) => [
  {
    title: 'Дата публикации',
    dataIndex: 'publicationTime',
    key: 'publicationTime',
    width: '20%'
  },
  {
    title: 'Заголовок',
    dataIndex: 'title',
    key: 'title',
    width: '20%',
    ellipsis: true
  },
  {
    title: 'Описание',
    dataIndex: 'description',
    key: 'description',
    width: '20%',
    ellipsis: true,
    render: (listAction) => (
      <td className="ant-table-cell ant-table-cell-ellipsis" dangerouslySetInnerHTML={{ __html: listAction }} />
    )
  },
  {
    title: 'Статус',
    dataIndex: 'resourceStatus',
    key: 'resourceStatus',
    width: '20%'
  },
  {
    title: 'Действие',
    dataIndex: 'action',
    key: 'action',
    width: '20%',
    render: (listAction) => (
      <Select onChange={handleDelete} style={{ width: '100%' }} placeholder="Выбрать">
        {/* eslint-disable-next-line react/destructuring-assignment */}
        {listAction.map(({ isLink, title, value, link }, index) => {
          if (isLink)
            return (
              <Option key={`${title}_${index}`} value="">
                <Link to={link}>{title}</Link>
              </Option>
            );

          return (
            <Option key={`${value}_${index}`} value={value}>
              {title}
            </Option>
          );
        })}
      </Select>
    )
  }
];

// //////////////////////////////////////////////////////////////////////////////////////////////////////////

export const projectApplicationColumns = (handleDelete) => [
  {
    title: 'Дата публикации',
    dataIndex: 'publicationTime',
    key: 'publicationTime',
    width: '20%',
    render: (listAction) => (
      <td className="ant-table-cell ant-table-cell-ellipsis" dangerouslySetInnerHTML={{ __html: listAction }} />
    )
  },
  {
    title: 'Заголовок',
    dataIndex: 'title',
    key: 'title',
    width: '20%',
    ellipsis: true
  },
  {
    title: 'Описание',
    dataIndex: 'description',
    key: 'description',
    width: '20%',
    ellipsis: true,
    render: (listAction) => (
      <td className="ant-table-cell ant-table-cell-ellipsis" dangerouslySetInnerHTML={{ __html: listAction }} />
    )
  },
  {
    title: 'Статус',
    dataIndex: 'resourceStatus',
    key: 'resourceStatus',
    width: '20%'
  },

  {
    title: 'Действие',
    dataIndex: 'action',
    key: 'action',
    width: '20%',
    render: (listAction) => (
      <Select onChange={(value, option) => handleDelete(value, option)} style={{ width: '100%' }} placeholder="Выбрать">
        {/* eslint-disable-next-line react/destructuring-assignment */}
        {listAction.map(({ isLink, title, value, link }, index) => {
          if (isLink) {
            return (
              <Option key={`${title}_${index}`} value="">
                <Link to={link}>{title}</Link>
              </Option>
            )
          };
          return (
            <Option key={`${value}_${index}`} value={`${value}/${index}`}>
              {title}
            </Option>
          );
        })}
      </Select>
    )
  }
];




// //////////////////////////////////////////////////////////////////////////////////////////////////////////
export const gamesColumns = () => [
  {
    title: 'Дата cоздания',
    dataIndex: 'creationTime',
    key: 'creationTime',
    width: '25%'
  },
  {
    title: 'Название игры',
    dataIndex: 'templateName',
    key: 'templateName',
    width: '75%',
    ellipsis: true,
    render: (listAction) => (
      <td className="ant-table-cell ant-table-cell-ellipsis" dangerouslySetInnerHTML={{ __html: listAction }} />
    )
  },

];

export const rowEstimateData = {
  material: '',
  averagePrice: 0,
  number: '',
  total: 0
};

export const resourceRow = {
  material: "",
  unit: "",
  number: "",
  price: "",
  total: ""
};

export const stepData = {
  title: '',
  description: '',
  picturePath: ''
};

export const exampleData = {
  name: '',
  shortDescription: '',
  source: '',
  picturePath: '',
  countryId: '',
  regionId: ''
};

export const columnsData = [
  {
    title: 'Материал',
    key: 'material',
    descToolTip: 'Материал'
  },
  {
    title: 'Средняя цена',
    key: 'averagePrice',
    descToolTip: 'Средняя цена'
  },
  {
    title: 'Количество',
    key: 'number',
    descToolTip: 'Количество'
  },
  {
    title: 'Итог',
    key: 'total',
    descToolTip: 'Итог'
  }
];

export const resourceColumns = [
  {
    title: 'Материал',
    key: 'material',
    descToolTip: 'Материал'
  },
  {
    title: 'Ед. изм.',
    key: 'unit',
    descToolTip: 'Ед. изм.'
  },
  {
    title: 'Количество',
    key: 'number',
    descToolTip: 'Количество'
  },
  {
    title: 'Цена',
    key: 'price',
    descToolTip: 'Цена'
  },
  {
    title: 'Итог',
    key: 'total',
    descToolTip: 'Итог'
  }
];