import { all } from 'redux-saga/effects';
import { UserSaga } from 'redux/saga/user';
import { combineModules } from 'modules/combineModules';
import { instructionsListSaga } from 'modules/instructions/redux/saga';
import { applicaionsListSaga } from 'modules/ProjectApplications/redux/saga/projectApplicationsSagas';
import { fileSaga } from 'redux/saga/file';
import { categoriesSaga } from 'redux/saga/categories';
import { GeoSaga } from 'redux/saga/geo';
import { projectApplicationDetailsSaga } from 'modules/ApplicatonDetails/redux/saga/applicationDetailsSagas'

export default function* rootSaga() {
  yield all([
    UserSaga(),
    instructionsListSaga(),
    // applicaionsListSaga(),
    fileSaga(),
    categoriesSaga(),
    GeoSaga(),
    ...combineModules.getSagas()
  ]);
}
