import { roles } from 'constans/ROLES';
import Header from 'components/header';
import React from 'react';
import Menu from 'components/menu';
import ContentGameDetail from 'modules/GameDetail/components/content';
import InfoReducer from 'modules/GameDetail/redux/reducers/infoGames';
import DetailReducer from 'modules/GameDetail/redux/reducers/detailGames';
import { infoGameSaga } from 'modules/GameDetail/redux/saga/infoGames';
import { detailGameSaga } from 'modules/GameDetail/redux/saga/detailGames';
import Access from 'pages/access';

class GameDetail {
  constructor() {
    this.permissions = {
      content: {
        administrator: true,
        moderator: true
      }
    };

    this.name = 'gamesDetail';
    this.role = roles.administrator;
    this.header = (props) => <Header {...props} />;
    this.menu = (props) => <Menu {...props} />;
    this.content = (props) => (
      // eslint-disable-next-line react/no-this-in-sfc
      <Access permissions={this.permissions.content} denied={<></>}>
        <ContentGameDetail {...props} />
      </Access>
    );
    this.reducer = () => ({ infogame: InfoReducer, detail: DetailReducer });
    this.sagas = () => [infoGameSaga(), detailGameSaga()];
  }
}

export default new GameDetail();
