/* eslint-disable react/react-in-jsx-scope */
import Access from 'pages/access';
import Header from 'components/header';
import Menu from 'components/menu';
import Content from './components/content';
import Reducer from './redux/reducers/projects';
import { roles } from '../../constans/ROLES';

class Projects {
  constructor() {
    this.permissions = {
      content: {
        administrator: true,
        moderator: true
      }
    };
    this.name = 'projects';
    this.role = roles.administrator;
    this.header = (props) => <Header {...props} />;
    this.menu = (props) => <Menu {...props} />;
    this.content = (props) => (
      // eslint-disable-next-line react/no-this-in-sfc
      <Access permissions={this.permissions.content} denied={<></>}>
        <Content {...props} />
      </Access>
    );

    this.reducer = () => ({ projects: Reducer });
    this.sagas = () => [];
  }
}

export default new Projects();
