import { roles } from 'constans/ROLES';
import Header from 'components/header';
import React from 'react';
import Menu from 'components/menu';
import ProjectApplicationDetails from 'modules/ApplicatonDetails/components/content';
import { projectApplicationDetailsSaga } from 'modules/ApplicatonDetails/redux/saga/applicationDetailsSagas';
import ProjectApplicationInfoReducer from 'modules/ApplicatonDetails/redux/reducers/applicationInfo';
import ApplicationDetailsReducer from 'modules/ApplicatonDetails/redux/reducers/applicationDetailsReducers';
import { infoApplicationSaga } from 'modules/ApplicatonDetails/redux/saga/formInfoSagas';
import Access from 'pages/access';

class ApplicationDetail {
  constructor() {
    this.permissions = {
      content: {
        administrator: true,
        moderator: true,
        user: true
      }
    };
    this.name = 'applicationDetails';
    this.role = roles.administrator;
    this.header = (props) => <Header {...props} />;
    this.menu = (props) => <Menu {...props} />;
    this.content = (props) => (
      // eslint-disable-next-line react/no-this-in-sfc
      <Access permissions={this.permissions.content} denied={<></>}>
        <ProjectApplicationDetails {...props} />
      </Access>
    );
    this.reducer = () => ({
      applicationInfo: ProjectApplicationInfoReducer,
      applictionDetails: ApplicationDetailsReducer
    });
    this.sagas = () => [projectApplicationDetailsSaga(), infoApplicationSaga()];
  }
}

export default new ApplicationDetail();
