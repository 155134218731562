import { INFO_INSTRUCTIONS_ACTION_TYPES } from 'modules/instructionDetail/redux/reducers/infoInstructions';

export const infoStartAC = () => ({ type: INFO_INSTRUCTIONS_ACTION_TYPES.GET.START });
export const infoSuccessAC = () => ({ type: INFO_INSTRUCTIONS_ACTION_TYPES.GET.SUCCESS });
export const infoErrorAC = (payload) => ({
  type: INFO_INSTRUCTIONS_ACTION_TYPES.GET.ERROR,
  payload
});
export const infoSetCategoriesAC = (payload) => ({
  type: INFO_INSTRUCTIONS_ACTION_TYPES.SET_CATEGORIES,
  payload
});
export const infoSetComplexityAC = (payload) => ({
  type: INFO_INSTRUCTIONS_ACTION_TYPES.SET_COMPLEXITY,
  payload
});
export const infoSetFinancingTypesAC = (payload) => ({
  type: INFO_INSTRUCTIONS_ACTION_TYPES.SET_FINANCING_TYPES,
  payload
});
