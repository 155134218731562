import React from 'react';
import { Route } from 'react-router';
import { SERVICES_URLS } from 'constans/SERVICES_URLS';

const AuthLink = () => (
  <Route
    component={() => {
      window.location.href = SERVICES_URLS.auth;
      return null;
    }}
  />
);

export default AuthLink;
