import { call, put, takeEvery } from 'redux-saga/effects';
import SERVICE_API from 'api';
import { DETAIL_INSTRUCTION_ACTION_TYPES } from 'modules/instructionDetail/redux/reducers/detailInstruction';
import {
  createSuccessInstructionAC,
  detailErrorAC,
  detailSetDataAC,
  detailSuccessAC
} from 'modules/instructionDetail/redux/actions/actionsDetailInstruction';
import { transformDataInstruction } from 'utils/transformData';

function* getDataDetailInstruction({ payload }) {
  try {
    const { data } = yield call(SERVICE_API.InstructionsAPI.getInstructionDetail, payload);
    console.log('instruction-from-back===>', transformDataInstruction(data));
    const actionPayload = transformDataInstruction(data);
    yield put(detailSetDataAC(actionPayload));
    yield put(detailSuccessAC());
  } catch ({ response }) {
    yield put(detailErrorAC(response.data));
  }
}

function* createInstruction({ payload }) {
  try {
    yield call(SERVICE_API.InstructionsAPI.createInstruction, payload);
    yield put(createSuccessInstructionAC());
  } catch ({ response }) {
    yield put(detailErrorAC(response.data));
  }
}
function* updateInstruction({ payload }) {
  try {
    yield call(SERVICE_API.InstructionsAPI.updateInstruction, payload);
    yield put(createSuccessInstructionAC());
  } catch ({ response }) {
    yield put(detailErrorAC(response.data));
  }
}

export function* detailInstructionSaga() {
  yield takeEvery(DETAIL_INSTRUCTION_ACTION_TYPES.GET.START, getDataDetailInstruction);
  yield takeEvery(DETAIL_INSTRUCTION_ACTION_TYPES.START_CREATE, createInstruction);
  yield takeEvery(DETAIL_INSTRUCTION_ACTION_TYPES.START_UPDATE, updateInstruction);
}
