/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-globals */
import { Col, InputNumber, Row, Slider, Tooltip, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import s from './index.module.scss';

const { Text } = Typography;

const calculateDuration = (numberOfDays) => {



  const durationUnits = {};
  durationUnits.years = Math.floor(numberOfDays / 365);
  durationUnits.months = Math.floor((numberOfDays - durationUnits.years * 365) / 30);
  durationUnits.days = numberOfDays - durationUnits.months * 30 - durationUnits.years * 365;

  if (durationUnits.years === 1) durationUnits.yearsTranslation = 'год';
  if (durationUnits.years > 1 && durationUnits.years < 5) durationUnits.yearsTranslation = 'года';
  if (durationUnits.years === 0 || durationUnits.years > 4) durationUnits.yearsTranslation = 'лет';

  return durationUnits;
};

const CustomSlider = ({ name, label, descToolTip, className, initialValue }) => {

  const valueFromForm = useSelector(state => state.applictionDetails?.data?.projectPartThree?.implementationPeriodDays)
  const form = useFormikContext();
  const [inputValue, setInputValue] = useState(valueFromForm);
  const maxNumOfDays = 3650;


  const onChange = (newValue) => {
    const projectDurationObject = calculateDuration(newValue);
    const projectDuration = newValue - projectDurationObject.days;
    setInputValue(projectDuration);
  };

  const changeMonths = (newValue) => {
    const projectDurationObject = calculateDuration(inputValue);
    const projectDuration = projectDurationObject.years * 365 + newValue * 30;
    setInputValue(projectDuration);
  };

  const changeYears = (newValue) => {
    const projectDurationObject = calculateDuration(inputValue);
    const projectDuration = newValue * 365 + projectDurationObject.months * 30;
    setInputValue(projectDuration);
  };


  useEffect(() => {
    setInputValue(valueFromForm)

  }, [valueFromForm])



  useEffect(() => {
    if (inputValue) form.setFieldValue(name, inputValue.toString());
  }, [inputValue])

  return (
    <Field>
      {({ field }) => {




        return (
          <div className={className}>
            {label && (
              <Tooltip title={descToolTip}>
                <label htmlFor={name} className={s.label}>
                  <Text type="secondary" className={s.text}>
                    {label}
                  </Text>
                  {descToolTip && <QuestionCircleOutlined />}
                </label>
              </Tooltip>
            )}
            <Row>
              <Col span={24}>
                <Slider
                  {...field}
                  name={name}
                  min={1}
                  max={maxNumOfDays}
                  onChange={onChange}
                  value={inputValue}
                />
              </Col>
            </Row>
            <div className={s.numberInputsContainer}>
              <div className={s.numberInput}>
                <InputNumber
                  size="large"
                  addonAfter={calculateDuration(inputValue).yearsTranslation}
                  min={0}
                  max={maxNumOfDays / 365}
                  style={{
                    margin: '0 16px'
                  }}
                  value={calculateDuration(inputValue).years}
                  onChange={changeYears}
                />
              </div>
              <div className={s.numberInput}>
                <InputNumber
                  size="large"
                  addonAfter="месяца"
                  min={0}
                  max={11}
                  style={{
                    margin: '0 16px'
                  }}
                  value={calculateDuration(inputValue).months}
                  onChange={changeMonths}
                />
              </div>
            </div>
          </div>
        );
      }}
    </Field>
  );
};

export default CustomSlider;
