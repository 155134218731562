import React from 'react';
import { Radio } from 'antd';
import { Field } from 'formik';

const RadioCustom = ({ name, value, label, isChecked }) => (
  <Field>
    {({ field }) => (
      <Radio {...field} value={value} name={name} checked={isChecked}>
        {label}
      </Radio>
    )}
  </Field>
);

export default RadioCustom;
