import React from 'react';
import { Spin } from 'antd';
import './index.scss';

const Download = () => (
  <div className="wrapper">
    <Spin />
  </div>
);

export default Download;
