import React, { useEffect } from 'react';
import { Layout, Spin, Typography } from 'antd';
import moment from 'moment';
import FORM from 'components/forms';
import GameDetailForm from 'modules/GameDetail/components/detailForm';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import {
  createStartGameAC,
  detailStartAC,
  resetStateGame,
  updateStartGameAC
} from 'modules/GameDetail/redux/actions/actionsDetailGames';
import {
  getIsLoading,
  getIsSuccess
} from 'modules/GameDetail/redux/selectors';
import { infoStartAC } from 'modules/GameDetail/redux/actions/actionsInfoGames';
import { initialValuesGame } from 'constants/GAMES_DATA';
import { GAME_VALIDATION_SCHEMA } from 'constants/VALIDATION_SCHEMS';
import { transformGameDataSend } from 'utils/transformData';
import s from './index.module.scss';

const { Text } = Typography;
const { Content } = Layout;
const { FormContainer } = FORM;

const ContentGameDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoading);
  const isSuccessCreateInstruction = useSelector(getIsSuccess);
  const history = useHistory();
  const dateNow = moment().format('DD.MM.YYYY');


  useEffect(() => {
    dispatch(infoStartAC());
    if (id) {
      dispatch(detailStartAC(id));
    }

    return () => dispatch(resetStateGame());
  }, []);

  useEffect(() => {
    if (isSuccessCreateInstruction) {
      history.push('/games');
    }
  }, [isSuccessCreateInstruction]);


  const handleSubmit = (values) => {
    if (id) {
      dispatch(updateStartGameAC({ id, data: transformGameDataSend(values) }));
    } else {
      dispatch(createStartGameAC(transformGameDataSend(values)));
    }
  };

  return (
    <Content className={s.content}>
      <h1 className={s.title}>Создание игры</h1>

      <div className={s.date}>Дата создания: {dateNow}</div>

      <FormContainer
        enableReinitialize
        initialValues={initialValuesGame}
        className={s.form}
        onSubmit={handleSubmit}
        validationSchema={GAME_VALIDATION_SCHEMA}
      >
        {({ errors, isValid }) => (
          <Spin spinning={isLoading}>
            <div className={s.instruction}>
              <GameDetailForm />

              {!!errors?.text && <Text type="danger">{errors?.text}</Text>}

              <button className={s.submit} type="submit" disabled={!isValid}>
                Сохранить
              </button>
            </div>
          </Spin>
        )}
      </FormContainer>
    </Content>
  );
};

export default ContentGameDetail;
