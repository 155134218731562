/* eslint-disable arrow-body-style */
import HOR from 'redux/HOR';
import { ActionTypes } from 'redux/utils/actionCreator';

const { pipeHigherOrderReducers, withLoadable, withResetState } = HOR;

export const APPLICATION_DETAILS_ACTION_TYPES = new ActionTypes('APPLICATION_DETAILS', [
  'SET_DETAIL',
  'START_CREATE',
  'SUCCESS_CREATE',
  'START_UPDATE'
])
  .listAT()
  .getActionTypes();

const initialState = {
  data: {}
};

const handleAction = {
  [APPLICATION_DETAILS_ACTION_TYPES.SET_DETAIL]: (state, params) => ({
    ...state,
    data: params
  }),
  [APPLICATION_DETAILS_ACTION_TYPES.SUCCESS_CREATE]: (state) => ({
    ...state,
    isLoading: false,
    isSuccess: true,
    error: null
  }),
  [APPLICATION_DETAILS_ACTION_TYPES.START_CREATE]: (state) => ({
    ...state,
    isLoading: true,
    isSuccess: false,
    error: null
  }),
  [APPLICATION_DETAILS_ACTION_TYPES.START_UPDATE]: (state) => ({
    ...state,
    isLoading: true,
    isSuccess: false,
    error: null
  })
};

const reducer = (state = initialState, action) => {
  return handleAction[action.type] ? handleAction[action.type](state, action.payload) : state;
};

export default pipeHigherOrderReducers(
  withResetState(APPLICATION_DETAILS_ACTION_TYPES.RESET_STATE, initialState),
  withLoadable({
    isLoadingActionType: [
      APPLICATION_DETAILS_ACTION_TYPES.GET.START,
      APPLICATION_DETAILS_ACTION_TYPES.START_CREATE
    ],
    successActionType: [APPLICATION_DETAILS_ACTION_TYPES.GET.SUCCESS],
    errorActionType: [APPLICATION_DETAILS_ACTION_TYPES.GET.ERROR]
  })
)(reducer);
