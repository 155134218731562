import React from 'react';
import { roles } from 'constans/ROLES';
import Header from 'components/header';
import Menu from 'components/menu';
// import ContentInstructions from 'modules/instructions/components/content';

import reducer from 'modules/ProjectApplications/redux/reducers/projectApplicationsReducers';
import { applicaionsListSaga } from 'modules/ProjectApplications/redux/saga/projectApplicationsSagas';
import Access from 'pages/access';
import ProjectApplicationsContent from './components/content/content';

class ProjectApplications {
  constructor() {
    this.permissions = {
      content: {
        administrator: true,
        moderator: true,
        user: true
      }
    };
    this.name = 'projectApplications';
    this.role = roles.administrator;
    this.header = (props) => <Header {...props} />;
    this.menu = (props) => <Menu {...props} />;
    this.content = (props) => (
      // eslint-disable-next-line react/no-this-in-sfc
      <Access permissions={this.permissions.content} denied={<></>}>
        <ProjectApplicationsContent {...props} />
      </Access>
    );
    this.reducer = () => ({ applications: reducer });
    this.sagas = () => [applicaionsListSaga()];
  }
}

export default new ProjectApplications();
