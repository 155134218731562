import { call, put, takeEvery } from 'redux-saga/effects'
import SERVICE_API from 'api'
import { categoriesErrorAC, categoriesSuccessAC, setCategoriesAC } from 'redux/actions/categories'
import { CATEGORIES_ACTION_TYPES } from 'redux/reducers/infoInstructions'

function* getCategories() {
  try {
    const { data } = yield call(SERVICE_API.UtilsApi.getCategories);

    yield put(setCategoriesAC(data));
    yield put(categoriesSuccessAC());
  } catch ({ response }) {
    yield put(categoriesErrorAC());
  }
}

export function* categoriesSaga() {
  yield takeEvery(CATEGORIES_ACTION_TYPES.GET.START, getCategories);
}