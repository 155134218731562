import { call, put, takeEvery } from 'redux-saga/effects';
import {
  geoErrorAC,
  geoSetCountriesAC,
  geoSetRegionsAC,
  geoSetRussiaRegionsAC,
  geoSuccessAC
} from 'redux/actions/geoActions';
import SERVICE_API from 'api';
import { GEO_ACTION_TYPES } from 'redux/reducers/geo';

function* getGeoInfo() {
  try {
    const { data } = yield call(SERVICE_API.UtilsApi.getCountries);
    yield put(geoSetCountriesAC(data));
    yield put(geoSuccessAC());
  } catch ({ response }) {
    yield put(geoErrorAC(response));
  }
}

function* getRegionsInfo({ payload }) {
  try {
    if (payload?.id) {
      const { data } = yield call(SERVICE_API.UtilsApi.getRegions, payload?.id);
      yield put(geoSetRegionsAC({ [payload?.name]: data }));
    } else {
      const { data } = yield call(SERVICE_API.UtilsApi.getRegions);
      yield put(geoSetRussiaRegionsAC(data));
    }
    yield put(geoSuccessAC());
  } catch ({ response }) {
    yield put(geoErrorAC(response));
  }
}

export function* GeoSaga() {
  yield takeEvery(GEO_ACTION_TYPES.GET.START, getGeoInfo);
  yield takeEvery(GEO_ACTION_TYPES.START_REGIONS, getRegionsInfo);
}
