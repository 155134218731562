import { roles } from 'constans/ROLES';
import Header from 'components/header';
import React from 'react';
import Menu from 'components/menu';
import ContentInstructionsDetail from 'modules/instructionDetail/components/content';
import { infoInstructionsSaga } from 'modules/instructionDetail/redux/saga/infoInstructions';
import InfoReducer from 'modules/instructionDetail/redux/reducers/infoInstructions';
import DetailReducer from 'modules/instructionDetail/redux/reducers/detailInstruction';
import { detailInstructionSaga } from 'modules/instructionDetail/redux/saga/detailInstruction';
import Access from 'pages/access';

class InstructionDetail {
  constructor() {
    this.permissions = {
      content: {
        administrator: true,
        moderator: true
      }
    };
    this.name = 'instructionsDetail';
    this.role = roles.administrator;
    this.header = (props) => <Header {...props} />;
    this.menu = (props) => <Menu {...props} />;
    this.content = (props) => (
      // eslint-disable-next-line react/no-this-in-sfc
      <Access permissions={this.permissions.content} denied={<></>}>
        <ContentInstructionsDetail {...props} />
      </Access>
    );
    this.reducer = () => ({
      info: InfoReducer,
      instructionsDetail: DetailReducer
    });
    this.sagas = () => [infoInstructionsSaga(), detailInstructionSaga()];
  }
}

export default new InstructionDetail();
