/* eslint-disable react/no-array-index-key */
/* eslint-disable react/self-closing-comp */
import React from 'react';
import { Divider, Typography, Button } from 'antd';
import TextEdditor from 'components/forms/textEdditor';
import UploadImg from 'components/forms/uploadImg';
import { useFormikContext, FieldArray } from 'formik';
import FieldArrayMultipleUpploads from 'components/forms/fiedArray/outerPart';
import ResourceTable from 'modules/ApplicatonDetails/components/editableTable';
import InputCustom from 'components/forms/Input';
import s from './index.module.scss';


const { Title } = Typography;

const PartTwo = () => {
  const { values } = useFormikContext();

  const desc = values.instructionDescription;

  return (
    <div className={s.desc}>
      <Title level={4}>Раздел 2</Title>

      <Divider orientation="left">Заполните все шесть вкладок</Divider>
      <div className={s.wrap}>
        <div className={s.inputs}>

          <TextEdditor
            className={s.textInput}
            name="projectPartTwo.description"
            label="1. Описание"
            detailValue={values.projectPartTwo.description}
            descToolTip="Должно включать описание идеи и ответына вопросы: Почему проект востребован? Почему его надо поддержать? Какие положительные эффекты можно от этого получтиь (можно включать иллюстрации в тексте)"
          />

          <TextEdditor
            className={s.textInput}
            name="projectPartTwo.restrictions"
            label="2 .Ограничения"
            detailValue={values.projectPartTwo.restrictions}
            descToolTip="Опишите, в каких случаях реализация проекта будет невозможна,  зона барбекю должна быть расположена в 60 метрах от лиственного леса и в 30 метрах от хвойного. Так читатель поймёт подходит ли ему этот проект"
          />
        </div>
      </div>

      <Divider orientation="left">3. Ресурсы </Divider>

      <ResourceTable />
      <div className={s.wrap}>
        <div className={s.inputs}>
          <TextEdditor
            className={s.textInput}
            name="projectPartTwo.minimumVersion"
            label="3.1. Минимальная версия"
            detailValue={values.projectPartTwo.minimumVersion}
            descToolTip="Укажите подробные позиции с указанием количества, которые необходимы для реализации проекта. При этом нельзя использовать конкретные названия брендов и магазинов"
          />

          <TextEdditor
            className={s.textInput}
            name="projectPartTwo.additionalFeatures"
            label="3.1.1. Дополнительные возможности"
            detailValue={values.projectPartTwo.additionalFeatures}
            descToolTip="Включение дополнительных пунктов (при наличии), при которых проект принесет еще больше пользы, но без которых можно обойтись. Например, лавочки для хозяев собак при установке площадки для выгула собак "
          />

          <InputCustom
            name="projectPartTwo.implementationCost"
            label="3.2. Стоимость реализации"
            placeholder="Укажите стоимость реализации проекта в рублях"
          />
        </div>
      </div>

      <Divider orientation="left" />
      <div className={s.wrap}>
        <div className={s.inputs}>

          <TextEdditor
            className={s.textInput}
            name="projectPartTwo.procedure"
            label="4. Порядок действий"
            detailValue={values.projectPartTwo.procedure}
            descToolTip="Укажите всю последовательность действий, которые вы запланировали для реализации проекта. Каждый шаг начинайте с новой строки, чтобы получился чеклист (можно вставлять иллюстрации в тексте, автоматически заполняется из инструкции)"
          />

          <TextEdditor
            className={s.textInput}
            name="projectPartTwo.sources"
            label="6 Источники"
            detailValue={values.projectPartTwo.sources}
            descToolTip="Перечислите источники ресурсов, из которых вы планируете привлекать средства, помощь иматериалы или укажите источники, из которых потенциально можно их взять. Например, грантовый конкурс Росмолодежи "
          />
        </div>

        <FieldArrayMultipleUpploads
          name='projectPartTwo.projectApplications'
          label="5. Приложения"
          descToolTip="Прикрепите дополнительные материалы, которые помогут другим участникам и экспертам понять ваш проект, например, чертежи для изготовления деталей, образцы заявлений, фотографии, дорожные карты, исследования"
          addButtonTitle="Добавить приложение к пректу"
          removeButtonTitle="Удалить приложение"
        />
      </div>
    </div>
  );
};

export default PartTwo;
