// /* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Button, Layout, Spin, Table } from 'antd';
import { instructionsColumns } from 'constants/TABLE_COLUMNS';
import { useDispatch, useSelector } from 'react-redux';
import {
  instructionStartAC,
  instructionStartDeleteAC
} from 'modules/instructions/redux/actions/actionsInstructions';
import { getInstructionsData, getIsLoading } from 'modules/instructions/redux/selectors';
import { instructionsTransformDataTable } from 'utils/transformData';
import { Link } from 'react-router-dom';
import s from './index.module.scss';

const { Content } = Layout;

const ContentInstructions = () => {
  const dispatch = useDispatch();
  const dataTable = useSelector(getInstructionsData);
  const isLoading = useSelector(getIsLoading);

  useEffect(() => {
    dispatch(instructionStartAC());
  }, []);

  const handleDelete = (id) => {
    if (id) {
      dispatch(instructionStartDeleteAC(id));
    }
  };

  return (
    <Content className={s.content}>
      <Spin spinning={isLoading}>
        <div className={s.block}>
          <h1 className={s.title}>Список инструкций</h1>
          <Button type="primary">
            <Link to={'/instructions-create'}>Добавить инструкцию</Link>
          </Button>
        </div>
        <div className={s.tableWrap}>
          <Table
            columns={instructionsColumns(handleDelete)}
            dataSource={instructionsTransformDataTable(dataTable)}
            pagination={false}
            scroll={{ y: 800 }}
          />
        </div>
      </Spin>
    </Content>
  );
};

export default ContentInstructions;
