import { combineReducers } from 'redux';
import { combineModules } from 'modules/combineModules';
import UserReducer from 'redux/reducers/user';
import FileReducer from 'redux/reducers/files';
import CategoriesReducer from 'redux/reducers/infoInstructions';
import GeoReducer from 'redux/reducers/geo';
import MenuReducer from 'redux/reducers/menu';

const appReducer = combineReducers({
  user: UserReducer,
  file: FileReducer,
  categories: CategoriesReducer,
  geo: GeoReducer,
  menu: MenuReducer,
  ...combineModules.getReducers()
});

console.log('APP REDUCER', { ...combineModules.getReducers() });

export default appReducer;
