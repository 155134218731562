/* eslint-disable arrow-body-style */
import { APPLICATION_DETAILS_ACTION_TYPES } from 'modules/ApplicatonDetails/redux/reducers/applicationDetailsReducers';

export const applicationDetailStartAC = (payload) => ({
  type: APPLICATION_DETAILS_ACTION_TYPES.GET.START,
  payload
});
export const applicationDetailsSuccessAC = () => ({ type: APPLICATION_DETAILS_ACTION_TYPES.GET.SUCCESS });
export const applicationDetailsErrorAC = (payload) => ({
  type: APPLICATION_DETAILS_ACTION_TYPES.GET.ERROR,
  payload
});
export const applicationDetailsSetDataAC = (payload) => ({
  type: APPLICATION_DETAILS_ACTION_TYPES.SET_DETAIL,
  payload
});
export const createStartApplicationAC = (payload) => {

  return {
    type: APPLICATION_DETAILS_ACTION_TYPES.START_CREATE,
    payload
  }
};
export const updateStartApplicationAC = (payload) => ({
  type: APPLICATION_DETAILS_ACTION_TYPES.START_UPDATE,
  payload
});
export const createSuccessApplicationDetailsAC = () => ({
  type: APPLICATION_DETAILS_ACTION_TYPES.SUCCESS_CREATE
});

export const resetStateApplication = () => ({ type: APPLICATION_DETAILS_ACTION_TYPES.RESET_STATE });
