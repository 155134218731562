import { call, put, takeEvery } from 'redux-saga/effects'
import SERVICE_API from 'api'
import { INFO_APPLICATIONS_ACTION_TYPES } from 'modules/ApplicatonDetails/redux/reducers/applicationInfo'
import {
  formInfoErrorAC,
  formSetCategoriesAC,
  formSetComplexityAC,
  formInfoSuccessAC
} from 'modules/ApplicatonDetails/redux/actions/formInfoActions'

function* getApplicationInfo() {
  try {
    const categories = yield call(SERVICE_API.ApplicationsAPI.applicationGetCategories);
    const complexity = yield call(SERVICE_API.ApplicationsAPI.applicationGetComplexities);
    yield put(formSetCategoriesAC(categories.data));
    yield put(formSetComplexityAC(complexity.data));
    yield put(formInfoSuccessAC());
  } catch ({ response }) {
    yield put(formInfoErrorAC(response.data));
  }
}

export function* infoApplicationSaga() {
  yield takeEvery(INFO_APPLICATIONS_ACTION_TYPES.GET.START, getApplicationInfo);
}
