import { INFO_APPLICATIONS_ACTION_TYPES } from 'modules/ApplicatonDetails/redux/reducers/applicationInfo';

export const fetchFormInfoStart = () => ({ type: INFO_APPLICATIONS_ACTION_TYPES.GET.START });
export const formInfoSuccessAC = () => ({ type: INFO_APPLICATIONS_ACTION_TYPES.GET.SUCCESS });
export const formInfoErrorAC = (payload) => ({
  type: INFO_APPLICATIONS_ACTION_TYPES.GET.ERROR,
  payload
});
export const formSetCategoriesAC = (payload) => ({
  type: INFO_APPLICATIONS_ACTION_TYPES.SET_CATEGORIES,
  payload
});
export const formSetComplexityAC = (payload) => ({
  type: INFO_APPLICATIONS_ACTION_TYPES.SET_COMPLEXITY,
  payload
});
export const formSetFinancingTypesAC = (payload) => ({
  type: INFO_APPLICATIONS_ACTION_TYPES.SET_FINANCING_TYPES,
  payload
});
