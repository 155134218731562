import HOR from 'redux/HOR';
import { ActionTypes } from 'redux/utils/actionCreator';

const { pipeHigherOrderReducers, withLoadable, withResetState } = HOR;
export const GEO_ACTION_TYPES = new ActionTypes('GEO', [
  'SET_COUNTRIES',
  'SET_RUSSIA_REGIONS',
  'START_REGIONS',
  'SET_REGIONS'
])
  .listAT()
  .getActionTypes();

const initialState = {
  countries: [],
  russiaRegions: [],
  regions: {}
};
const handleAction = {
  [GEO_ACTION_TYPES.SET_COUNTRIES]: (state, params) => ({
    ...state,
    countries: params
  }),
  [GEO_ACTION_TYPES.SET_RUSSIA_REGIONS]: (state, params) => ({
    ...state,
    russiaRegions: params
  }),
  [GEO_ACTION_TYPES.START_REGIONS]: (state) => ({
    ...state,
    error: null,
    fieldsErrors: null,
    isLoading: true,
    success: null
  }),
  [GEO_ACTION_TYPES.SET_REGIONS]: (state, params) => ({
    ...state,
    regions: { ...state.regions, ...params }
  })
};

const reducer = (state = initialState, action) =>
  handleAction[action.type] ? handleAction[action.type](state, action.payload) : state;

export default pipeHigherOrderReducers(
  withResetState(GEO_ACTION_TYPES.RESET_STATE, initialState),
  withLoadable({
    isLoadingActionType: [GEO_ACTION_TYPES.GET.START],
    successActionType: [GEO_ACTION_TYPES.GET.SUCCESS],
    errorActionType: [GEO_ACTION_TYPES.GET.ERROR]
  })
)(reducer);
