export const instructionsCrumbs = [
  {
    title: 'Список инструкций',
    path: '/'
  },
  {
    title: 'Редактирование инструкции'
  }
];

export const projectApplicationsCrumbs = [
  {
    title: 'Список заявок',
    path: '/projects'
  },
  {
    title: 'Редактирование заявок'
  }
];