import { useSelector } from 'react-redux';
import './index.scss';

const Access = ({ permissions, children, denied }) => {
  const user = useSelector((state) => state.user);
  const access = permissions[user.role];

  const content = access ? children : denied;
  return content;
};

export default Access;
