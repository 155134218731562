/* eslint-disable import/no-unresolved */
import {
    applicationErrorAC,
    applicationSetListAC,
    applicationSuccessAC
} from 'modules/ProjectApplications/redux/actions/projectApplicatonActions';
import { call, put, takeEvery } from 'redux-saga/effects';
import SERVICE_API from 'api';
import { APPLICATIONS_ACTION_TYPES } from 'modules/ProjectApplications/redux/reducers/projectApplicationsReducers'

function* getApplicationsList() {
    try {
        const { data } = yield call(SERVICE_API.ApplicationsAPI.getApplicationsList);

        yield put(applicationSetListAC(data));
        yield put(applicationSuccessAC());
    } catch ({ response }) {
        yield put(applicationErrorAC(response.data));
    }
}

function* deleteApplication({ payload }) {
    try {
        yield call(SERVICE_API.ApplicationsAPI.deleteApplication, payload);
        const { data } = yield call(SERVICE_API.ApplicationsAPI.getApplicationsList);
        yield put(applicationSetListAC(data));
        yield put(applicationSuccessAC());
    } catch ({ response }) {
        yield put(applicationErrorAC(response.data));
    }
}

function* changeApplicationStatus({ payload }) {
    try {

        console.log('changeApplicationStatus triggered', payload)
        yield call(SERVICE_API.ApplicationsAPI.applicationChangeStatus, payload);
        const { data } = yield call(SERVICE_API.ApplicationsAPI.getApplicationsList);
        yield put(applicationSetListAC(data));
        yield put(applicationSuccessAC());
    } catch ({ response }) {
        yield put(applicationErrorAC(response.data));
    }
}

export function* applicaionsListSaga() {
    yield takeEvery(APPLICATIONS_ACTION_TYPES.GET.START, getApplicationsList);
    yield takeEvery(APPLICATIONS_ACTION_TYPES.START_DELETE, deleteApplication);
    yield takeEvery(APPLICATIONS_ACTION_TYPES.START_CHANGE_STATUS, changeApplicationStatus);
}
