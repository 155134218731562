export const statusList = [
  {
    title: 'Опубликовано',
    value: 'PUBLISHED'
  },
  {
    title: 'Скрыто',
    value: 'UNPUBLISHED'
  }
];
