/* eslint-disable no-param-reassign */
/* eslint-disable dot-notation */
/* eslint-disable no-useless-computed-key */
import React from 'react';

import { useSelector } from 'react-redux';
import { Divider, Typography } from 'antd';
import TextEdditor from 'components/forms/textEdditor';
import InputCustom from 'components/forms/Input';
import CheckboxGroup from 'components/forms/checkboxGroup/checkbox';
import RadioCustom from 'components/forms/radio';
// import UploadImg from 'components/forms/uploadImg';
import { useFormikContext, Field } from 'formik';
import {
  getCategories,
  getComplexity
} from 'modules/ApplicatonDetails/redux/applicationDetailsSelectors';
import SelectCustom from 'components/forms/Select';
import { transfromData } from 'utils/transformData';
import CustomSlider from 'components/forms/slider';

import s from './index.module.scss';

const { Title } = Typography;

const PartThree = () => {
  const { values } = useFormikContext();
  const categories = useSelector(getCategories);
  const complexityData = useSelector(getComplexity);

  const {
    examples,
    projectCategoriesId,
    projectCategories,
    results,
    indicators,
    text,
    projectComplexityImplementationId,
    implementationPeriodDays,
    quoteText,
    quoteAuthor
  } = values.projectPartThree;



  const checkboxGroupOptions = (arrayOfObjects) => {
    const newarr = arrayOfObjects?.map(object => {
      const newObject = { ...object }
      newObject.label = object.value;
      newObject.value = object.id
      delete newObject.id
      return newObject
    })
    return newarr
  }

  const precheckedOptions =
    values.projectPartThree.projectCategories?.map((category) => category.id)


  return (
    <div className={s.limits}>
      <Title level={4}>Раздел 3</Title>

      {/* <Divider orientation="left">Раздел три</Divider> */}

      <div className={s.wrap}>
        <div className={s.inputs}>
          <TextEdditor
            className={s.textInput}
            name="projectPartThree.examples"
            label="1. Примеры"
            detailValue={examples}
            descToolTip="Укажите ссылки на примеры реализации инструкции"
          />

          <CheckboxGroup
            label={'2. Категории'}
            name="projectPartThree.projectCategoriesId"

            checkboxGroupOptions={checkboxGroupOptions(categories)}
            spanSize={8}
            descToolTip="Выберите наиболее подходящие категории для упрощения поиска"
            className={s.slider}
          />

          <TextEdditor
            className={s.textInput}
            name="projectPartThree.results"
            label="3. Результаты "
            detailValue={values.projectPartThree.results}
            descToolTip="Выберите самый значимый результат (На выбор)"
          />

          <TextEdditor
            className={s.textInput}
            name="projectPartThree.indicators"
            label="3.1. Показатели"
            detailValue={values.projectPartThree.indicators}
            descToolTip="Укажите один или несколько конкретных показателей, например, количество ежедневных посетителей, сумма сэкономленных денег за год"
          />

          <TextEdditor
            className={s.textInput}
            name="projectPartThree.text"
            label="3.2. Текст"
            detailValue={values.projectPartThree.text}
            descToolTip="Если ваш главный результат не может быть выражен в цифрах, опишите его в текстовом формате"
          />

          <SelectCustom
            className={s.slider}
            name="projectPartThree.projectComplexityImplementationId"
            label="4. Сложность реализации"
            descTooltip="Оцените сложность реализации проекта (Легко / Нормально / Сложно)"
            list={transfromData(complexityData || [])}
            detailValue={values.projectPartThree.projectComplexityImplementationId}
            placeholder="Выберите сложность"
          />

          <CustomSlider
            name="projectPartThree.implementationPeriodDays"
            label={'5. Срок реализации'}
            className={s.slider}
            initialValue={Number(implementationPeriodDays)}
          />

          <TextEdditor
            className={s.textInput}
            name="projectPartThree.quoteText"
            label="6. Цитата по теме"
            detailValue={values.projectPartThree.quoteText}
            descToolTip="Добавьте вдохновляющую цитату общественного деятеля"
          />

          <InputCustom
            name="projectPartThree.quoteAuthor"
            label="Автор цитаты"
            placeholder="Введите имя автора цитаты"
          />
        </div>
      </div>
    </div>
  );
};

export default PartThree;
