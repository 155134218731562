import HOR from 'redux/HOR';
import { ActionTypes } from 'redux/utils/actionCreator';

const { pipeHigherOrderReducers, withLoadable, withResetState } = HOR;
export const PROJECTS_ACTION_TYPES = new ActionTypes('PROJECTS').listAT().getActionTypes();

const initialState = {
  projects: []
};

const stateHandler = (state, params) => ({ ...state, ...params });
const handleAction = {
  [PROJECTS_ACTION_TYPES.GET.SUCCESS]: stateHandler
};

const reducer = (state = initialState, action) =>
  handleAction[action.type] ? handleAction[action.type](state, action.payload) : state;

export default pipeHigherOrderReducers(
  withResetState(PROJECTS_ACTION_TYPES.RESET_STATE, initialState),
  withLoadable({
    isLoadingActionType: [PROJECTS_ACTION_TYPES.GET.START],
    successActionType: [PROJECTS_ACTION_TYPES.GET.SUCCESS],
    errorActionType: [PROJECTS_ACTION_TYPES.GET.ERROR]
  })
)(reducer);
