/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Select, Tooltip, Typography } from 'antd';
import { Field, useFormikContext } from 'formik';
import { QuestionCircleOutlined } from '@ant-design/icons';
import s from './index.module.scss';

const { Option } = Select;
const { Text } = Typography;

const SelectCustom = ({
  list,
  name,
  placeholder,
  label = '',
  mode = false,
  descTooltip,
  isSearch = false,
  className = s.field,
  onChange,
  detailValue = ''
}) => {
  const filterOptions = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const { values } = useFormikContext();
  const preSelectedValue = values.projectPartThree?.projectComplexityImplementation?.value;

  const selectValue = (form) => form.values[name] || preSelectedValue || detailValue;

  return (
    <Field>
      {({ form, meta }) => {
        const isError = !!(meta.error && meta.touched);
        const handleChange = (value) => {
          form.setFieldValue(name, value);
          // eslint-disable-next-line no-unused-expressions
          onChange && onChange(value);
        };
        return (
          <div className={className}>
            {label && (
              <Tooltip title={descTooltip}>
                <label htmlFor={name} className={s.label}>
                  <Text type="secondary" className={s.text}>
                    {label}
                  </Text>
                  {descTooltip && <QuestionCircleOutlined />}
                </label>
              </Tooltip>
            )}

            <Select
              showSearch={isSearch}
              onChange={handleChange}
              style={{ width: '100%' }}
              placeholder={placeholder}
              mode={mode}
              filterOption={isSearch && filterOptions}
              value={selectValue(form)}
            >
              {list?.map(({ value, title }, index) => (
                <Option key={`${value}_${index}`} value={value}>
                  {title}
                </Option>
              ))}
            </Select>

            {isError && (
              <label htmlFor={name}>
                <Text type="danger">{meta.error?.value}</Text>
              </label>
            )}
          </div>
        );
      }}
    </Field>
  );
};

export default SelectCustom;
