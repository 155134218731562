import HOR from 'redux/HOR';
import { ActionTypes } from 'redux/utils/actionCreator';

const { pipeHigherOrderReducers, withLoadable, withResetState } = HOR;
export const CATEGORIES_ACTION_TYPES = new ActionTypes('CATEGORIES', ['SET_CATEGORIES']).listAT().getActionTypes();

const initialState = {
  categories: ''
};
const handleAction = {
  [CATEGORIES_ACTION_TYPES.SET_CATEGORIES]: (state, params) => ({
    ...state,
    categories: params
  })
};

const reducer = (state = initialState, action) =>
  handleAction[action.type] ? handleAction[action.type](state, action.payload) : state;

export default pipeHigherOrderReducers(
  withResetState(CATEGORIES_ACTION_TYPES.RESET_STATE, initialState),
  withLoadable({
    isLoadingActionType: [CATEGORIES_ACTION_TYPES.GET.START],
    successActionType: [CATEGORIES_ACTION_TYPES.GET.SUCCESS],
    errorActionType: [CATEGORIES_ACTION_TYPES.GET.ERROR]
  })
)(reducer);
