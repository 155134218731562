/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { Layout, Spin, Typography, Modal, Button, Space } from 'antd';
import BreadCrumbs from 'components/breadCrumbs';
import { projectApplicationsCrumbs } from 'constants/CRUMBS';
import moment from 'moment';
import FORM from 'components/forms';
import SideInfo from 'modules/ApplicatonDetails/components/sideInfo';
import InstructionDetailForm from 'modules/ApplicatonDetails/components/detailForm';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import {
  createStartApplicationAC,
  applicationDetailStartAC,
  resetStateApplication,
  updateStartApplicationAC
} from 'modules/ApplicatonDetails/redux/actions/applicationDetailsActions'
import {
  getDataDetail,
  getIsLoading,
  getIsSuccess
} from 'modules/ApplicatonDetails/redux/applicationDetailsSelectors';

import { projectApplicationInitialValues } from 'constants/INSTRUCTIONS_DATA';
import { APPLICATION_VALIDATION_SCHEMA } from 'constants/VALIDATION_SCHEMS';
import { transformApplicationDatabeforeSend } from 'utils/transformData';


import s from './index.module.scss';



const { Text } = Typography;
const { Content } = Layout;
const { FormContainer } = FORM;

const ProjectApplicationDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const preloadedApplicationData = useSelector(getDataDetail);
  const isLoading = useSelector(getIsLoading);
  const isSuccessCreateInstruction = useSelector(getIsSuccess);
  const history = useHistory();
  const dateNow = moment().format('DD.MM.YYYY');
  const [visible, setIsModalVisible] = useState(false);


  function success() {
    Modal.success({
      content: 'Заявка сохранена',
      visible,
      onOk() { history.push('/projects') }
    });
  }


  useEffect(() => {

    if (id) {

      dispatch(applicationDetailStartAC(id));
    }

    return () => dispatch(resetStateApplication());
  }, []);

  useEffect(() => {
    if (isSuccessCreateInstruction) {
      success()
    }
  }, [isSuccessCreateInstruction]);

  // useEffect(() => {
  //   history.push('/projects')
  // }, [modalIsUp])


  const isSelectorEmpty =
    preloadedApplicationData.constructor === Object && Object.keys(preloadedApplicationData).length
      ? preloadedApplicationData
      : projectApplicationInitialValues;


  const handleSubmit = (values) => {

    if (id) {
      dispatch(updateStartApplicationAC({ id, data: transformApplicationDatabeforeSend(values) }));

    } else {

      dispatch(createStartApplicationAC(transformApplicationDatabeforeSend(values)));
    }
  };

  return (

    <>
      <Content className={s.content} >
        <BreadCrumbs list={projectApplicationsCrumbs} />
        <h1 className={s.title}>Редактирование заявки</h1>

        <div className={s.date}>Дата создания: {dateNow}</div>

        <FormContainer
          enableReinitialize
          initialValues={isSelectorEmpty}
          className={s.form}
          onSubmit={handleSubmit}
          validationSchema={APPLICATION_VALIDATION_SCHEMA}
        >
          {({ errors, isValid }) => (
            <Spin spinning={isLoading}>
              <div className={s.instruction}>
                <InstructionDetailForm />
                {!!errors?.text && <Text type="danger">{errors?.text}</Text>}

                <button className={s.submit} type="submit" disabled={!isValid}>
                  Сохранить
                </button>
              </div>
            </Spin>
          )}
        </FormContainer>
      </Content>

    </>


  );
};

export default ProjectApplicationDetails;