/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import s from 'modules/instructionDetail/components/detailForm/index.module.scss';
import SelectCustom from 'components/forms/Select';
import { transfromData } from 'utils/transformData';
import { useDispatch, useSelector } from 'react-redux';
import { getCountries, getRegions } from 'redux/selectors';
import { geoStartRegionsAC } from 'redux/actions/geoActions';
import TextEdditor from 'components/forms/textEdditor';
import UploadImg from 'components/forms/uploadImg';
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import InputCustom from 'components/forms/Input';

const ExampleItem = ({ index, handleDelete, data }) => {
  const dispatch = useDispatch();
  const countriesData = useSelector(getCountries);
  const regions = useSelector(
    getRegions(`instructionExamples.instructionExampleItems.${index}.regionId`)
  );
  const [countryId, setCountryId] = useState(null);

  useEffect(() => {
    if (countryId || data.countryId) {
      dispatch(
        geoStartRegionsAC({
          id: countryId || data.countryId,
          name: `instructionExamples.instructionExampleItems.${index}.regionId`
        })
      );
    }
  }, [countryId, data.countryId]);

  const handleChangeCountryId = (value) => setCountryId(value);

  return (
    <div className={s.example}>
      <div className={s.exampleRegions}>
        <SelectCustom
          isSearch
          name={`instructionExamples.instructionExampleItems.${index}.countryId`}
          list={transfromData(countriesData || [])}
          onChange={handleChangeCountryId}
          detailValue={data.countryId}
          label="Выберите страну"
        />

        <SelectCustom
          isSearch
          name={`instructionExamples.instructionExampleItems.${index}.regionId`}
          list={transfromData(regions || [])}
          detailValue={data.regionId}
          label="Выберите регион"
        />
      </div>

      <div className={s.exampleWrap}>
        <div className={s.exampleInputs}>
          <InputCustom
            className={s.textarea}
            name={`instructionExamples.instructionExampleItems.${index}.title`}
            label="Название"
            placeholder="Введите название"
          />
          <TextEdditor
            className={s.textarea}
            name={`instructionExamples.instructionExampleItems.${index}.shortDescription`}
            label="Краткое описание"
            detailValue={data.shortDescription}
          />
          <TextEdditor
            className={s.textarea}
            name={`instructionExamples.instructionExampleItems.${index}.source`}
            label="Источник"
            detailValue={data.source}
          />
        </div>

        <UploadImg
          name={`instructionExamples.instructionExampleItems.${index}.picturePath`}
          label="Обложка"
          detailPath={data.picturePath}
        />
      </div>

      <Button shape="circle" icon={<CloseOutlined />} onClick={handleDelete(index)} />
    </div>
  );
};

export default ExampleItem;
