export class Container {
  constructor() {
    this.modules = [];
  }

  subscribe(route, module) {
    this.modules = [...this.modules, { route, module }];

    return this;
  }

  activeModule = (name) => this.modules.find((obj) => obj.module.name === name);

  getReducers = () =>
    this.modules.reduce((reducers, item) => ({ ...reducers, ...item.module.reducer() }), {});

  getSagas = () => this.modules.reduce((sagas, item) => [...sagas, ...item.module.sagas()], []);
}
