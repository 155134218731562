import React from 'react';
import { Link } from 'react-router-dom'
import { Button } from 'antd'
import "./index.scss";

const NoPage = () => (
  <section className="no-page">
    <h1 className="no-page__title">404</h1>
    <div className="no-page__subtitle">Ошибка - страница не найдена</div>
    <div className="no-page__desc">К сожалению, такой страницы не существует, вы можете перейти на главную</div>
    <Link to="/">
      <Button type="primary">На главную</Button>
    </Link>
  </section>
);

export default NoPage;
