import React, { useState } from 'react';
import { Layout, Button, Modal } from 'antd';
import Styles from './index.module.scss';

const { Content } = Layout;

const MyContent = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const toggle = () => {
    setIsModalVisible((modalVisible) => !modalVisible);
  };

  return (
    <Content className={Styles.content}>
      <div className={Styles.wrapper}>
        <h1 className={Styles.title}>Список новостей</h1>
        <Button type="primary" onClick={toggle}>
          Добавить новость
        </Button>
      </div>
      <div className={Styles.table__wrap}>
        <h1>Данные отсутсвуют</h1>
      </div>
      <Modal title="Basic Modal" visible={isModalVisible} onOk={toggle} onCancel={toggle}>
        <p>Some contents...</p>
      </Modal>
    </Content>
  );
};

export default MyContent;
