import moment from 'moment';

export const formatedDate = (date) => {
  if (!date) return '';

  return moment
    .utc(date * 1000)
    .local()
    .format('DD.MM.YYYY');
};
