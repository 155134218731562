import { call, put, takeEvery } from 'redux-saga/effects'
import SERVICE_API from 'api'
import { INFO_INSTRUCTIONS_ACTION_TYPES } from 'modules/instructionDetail/redux/reducers/infoInstructions'
import {
  infoErrorAC,
  infoSetCategoriesAC,
  infoSetComplexityAC,
  infoSetFinancingTypesAC,
  infoSuccessAC
} from 'modules/instructionDetail/redux/actions/actionsInfoInstructions'

function* getInfoInstructions() {
  try {
    const categories = yield call(SERVICE_API.UtilsApi.getCategories);
    const financing = yield call(SERVICE_API.UtilsApi.getFinancingTypes);
    const complexity = yield call(SERVICE_API.UtilsApi.getComplexity);

    yield put(infoSetCategoriesAC(categories.data));
    yield put(infoSetComplexityAC(complexity.data));
    yield put(infoSetFinancingTypesAC(financing.data));

    yield put(infoSuccessAC());
  } catch ({ response }) {
    yield put(infoErrorAC(response.data));
  }
}

export function* infoInstructionsSaga() {
  yield takeEvery(INFO_INSTRUCTIONS_ACTION_TYPES.GET.START, getInfoInstructions);
}
