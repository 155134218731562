import React from 'react';
import { Divider, Typography } from 'antd';
import InputCustom from 'components/forms/Input';
import TextEdditor from 'components/forms/textEdditor';
import UploadImg from 'components/forms/uploadImg';
import { useSelector } from 'react-redux';
import SelectCustom from 'components/forms/Select';
import { transfromData } from 'utils/transformData';
// import {
//   getCategories,
//   getComplexity,
//   getFinancingTypes
// } from 'modules/instructionDetail/redux/selectors';
import { useFormikContext } from 'formik';
import s from './index.module.scss';

const { Title } = Typography;

const PartOne = () => {
  const { values } = useFormikContext();
  // const categoriesData = useSelector(getCategories);
  // const financingData = useSelector(getFinancingTypes);
  // const complexityData = useSelector(getComplexity);

  return (
    <div>
      <Title level={4}>Раздел 1</Title>

      <Divider orientation="left" />

      <div className={s.wrap}>
        <div className={s.inputs}>
          <InputCustom
            name="projectPartOne.coordinates"
            label="Место реализации проекта"
            descToolTip="Укажите конкретное место на карте где вы планируете реализовать проект"
            placeholder="Введите адрес"
          />

          <InputCustom
            name="projectPartOne.title"
            label="Название проекта"
            descToolTip="Впишите полное название проекта"
            placeholder="Введите название"
          />

          <TextEdditor
            className={s.textInput}
            name="projectPartOne.call"
            label="Призыв"
            detailValue={values.projectPartOne.call}
            descToolTip="Призыв (до 100 символов), который привлечет новых участников в проект"
          />

          <TextEdditor
            className={s.textInput}
            name="projectPartOne.shortTitle"
            label="Краткое название"
            detailValue={values.projectPartOne.shortTitle}
            descToolTip="Краткое название, отражающее суть, понятную пользователям"
          />

        </div>

        <UploadImg
          name="projectPartOne.picturePath"
          label="Иллюстрация"
          descToolTip="Добавьте изображение для карточки проекта, которое пользователь увидит при просмотре списка"
          detailPath={values.projectPartOne.picturePath}
        />
      </div>
    </div>
  );
};

export default PartOne;
