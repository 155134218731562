import React from 'react';
import s from "./index.module.scss";

const StatusItem = ({ status }) => {
  const generateStatus = () => {
    switch (status) {
      case 'UNPUBLISHED':
        return 'Скрыто';

      case 'PUBLISHED':
        return 'Опубликовано';

      default:
        return '';
    }
  };

  return (
    <div className={s.status}>
      <span className={s[status]}/>
      {generateStatus()}
    </div>
  );
};

export default StatusItem;
