import { DETAIL_INSTRUCTION_ACTION_TYPES } from 'modules/instructionDetail/redux/reducers/detailInstruction';

export const detailStartAC = (payload) => ({
  type: DETAIL_INSTRUCTION_ACTION_TYPES.GET.START,
  payload
});
export const detailSuccessAC = () => ({ type: DETAIL_INSTRUCTION_ACTION_TYPES.GET.SUCCESS });
export const detailErrorAC = (payload) => ({
  type: DETAIL_INSTRUCTION_ACTION_TYPES.GET.ERROR,
  payload
});

export const detailSetDataAC = (payload) => ({
  type: DETAIL_INSTRUCTION_ACTION_TYPES.SET_DETAIL,
  payload
});
export const createStartInstructionAC = (payload) => ({
  type: DETAIL_INSTRUCTION_ACTION_TYPES.START_CREATE,
  payload
});
export const updateStartInstructionAC = (payload) => ({
  type: DETAIL_INSTRUCTION_ACTION_TYPES.START_UPDATE,
  payload
});
export const createSuccessInstructionAC = () => ({
  type: DETAIL_INSTRUCTION_ACTION_TYPES.SUCCESS_CREATE
});

export const resetStateInstruction = () => ({ type: DETAIL_INSTRUCTION_ACTION_TYPES.RESET_STATE });
