import HOR from 'redux/HOR';
import { ActionTypes } from 'redux/utils/actionCreator';

const { pipeHigherOrderReducers, withLoadable, withResetState } = HOR;
export const FILE_ACTION_TYPES = new ActionTypes('FILE', ['SET_PATH']).listAT().getActionTypes();

const initialState = {
  path: ''
};
const handleAction = {
  [FILE_ACTION_TYPES.SET_PATH]: (state, params) => ({
    ...state,
    ...params
  })
};

const reducer = (state = initialState, action) =>
  handleAction[action.type] ? handleAction[action.type](state, action.payload) : state;

export default pipeHigherOrderReducers(
  withResetState(FILE_ACTION_TYPES.RESET_STATE, initialState),
  withLoadable({
    isLoadingActionType: [FILE_ACTION_TYPES.GET.START],
    successActionType: [FILE_ACTION_TYPES.GET.SUCCESS],
    errorActionType: [FILE_ACTION_TYPES.GET.ERROR]
  })
)(reducer);
