/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { Layout, Spin, Typography, Modal, Button, Space } from 'antd';
import BreadCrumbs from 'components/breadCrumbs';
import { instructionsCrumbs } from 'constants/CRUMBS';
import moment from 'moment';
import FORM from 'components/forms';
import SideInfo from 'modules/instructionDetail/components/sideInfo';
import InstructionDetailForm from 'modules/instructionDetail/components/detailForm';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import {
  createStartInstructionAC,
  detailStartAC,
  resetStateInstruction,
  updateStartInstructionAC
} from 'modules/instructionDetail/redux/actions/actionsDetailInstruction';
import {
  getDataDetail,
  getIsLoading,
  getIsSuccess
} from 'modules/instructionDetail/redux/selectors';
import { infoStartAC } from 'modules/instructionDetail/redux/actions/actionsInfoInstructions';
import { initialValuesInstruction } from 'constants/INSTRUCTIONS_DATA';
import { INSTRUCTION_VALIDATION_SCHEMA } from 'constants/VALIDATION_SCHEMS';
import { transformDataSend } from 'utils/transformData';
import useSelection from 'antd/lib/table/hooks/useSelection';

import s from './index.module.scss';

const { Text } = Typography;
const { Content } = Layout;
const { FormContainer } = FORM;

const ContentInstructionsDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const instructionData = useSelector(getDataDetail);
  const isLoading = useSelector(getIsLoading);
  const isSuccessCreateInstruction = useSelector(getIsSuccess);
  const history = useHistory();
  const dateNow = moment().format('DD.MM.YYYY');
  const [visible, setIsModalVisible] = useState(false);

  useEffect(() => {
    dispatch(infoStartAC());
    if (id) {
      dispatch(detailStartAC(id));
    }

    return () => dispatch(resetStateInstruction());
  }, []);

  useEffect(() => {
    if (isSuccessCreateInstruction) {
      history.push('/');
    }
  }, [isSuccessCreateInstruction]);

  console.log('instructionData===>', instructionData);

  const isSelectorEmpty =
    instructionData.constructor === Object && Object.keys(instructionData).length
      ? instructionData
      : initialValuesInstruction;

  function success() {
    Modal.success({
      content: 'Инструкция сохранена',
      visible
    });
  }

  const handleSubmit = (values) => {
    if (id) {
      dispatch(updateStartInstructionAC({ id, data: transformDataSend(values) }));
    } else {
      dispatch(createStartInstructionAC(transformDataSend(values)));
    }
    success();
  };

  return (
    <>
      <Content className={s.content}>
        <BreadCrumbs list={instructionsCrumbs} />
        <h1 className={s.title}>Редактирование инструкции</h1>

        <div className={s.date}>Дата создания: {dateNow}</div>

        <FormContainer
          enableReinitialize
          initialValues={isSelectorEmpty}
          className={s.form}
          onSubmit={handleSubmit}
          validationSchema={INSTRUCTION_VALIDATION_SCHEMA}
        >
          {({ errors, isValid }) => (
            <Spin spinning={isLoading}>
              <div className={s.instruction}>
                <SideInfo />
                <InstructionDetailForm />

                {!!errors?.text && <Text type="danger">{errors?.text}</Text>}

                <button className={s.submit} type="submit" disabled={!isValid}>
                  Сохранить
                </button>
              </div>
            </Spin>
          )}
        </FormContainer>
      </Content>
    </>
  );
};

export default ContentInstructionsDetail;
