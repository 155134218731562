/* eslint-disable arrow-body-style */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Button, Tooltip } from 'antd';
import { CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { FieldArray } from 'formik';
import { resourceColumns, resourceRow } from 'constants/TABLE_COLUMNS';
import InputCustom from 'components/forms/Input';
import s from './index.module.scss';

const ResourceTable = () => (
  <div className={s.table}>
    <div className={s.header}>
      {resourceColumns.map(({ title, key, descToolTip }, index) => (
        <div className={s.column} key={`${key}_${index}`}>
          <Tooltip title={descToolTip} className={s.tooltip}>
            <div>{title}</div>
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
      ))}
    </div>
    <div className={s.body}>
      <FieldArray name="projectPartTwo.projectResource.resourceRows">
        {({ remove, push, form }) => {
          const handleDelete = (index) => () => remove(index);
          const handleAddRow = () => push(resourceRow);
          return (
            <>
              <div className={s.rows}>
                {form?.values?.projectPartTwo?.projectResource?.resourceRows.map((_, index) => {


                  return (
                    <div className={s.row}>
                      <InputCustom
                        name={`projectPartTwo.projectResource.resourceRows[${index}].material`}
                      />
                      <InputCustom
                        name={`projectPartTwo.projectResource.resourceRows[${index}].unit`}
                      />
                      <InputCustom
                        name={`projectPartTwo.projectResource.resourceRows[${index}].number`}
                      />
                      <InputCustom
                        name={`projectPartTwo.projectResource.resourceRows[${index}].price`}
                      />
                      <InputCustom
                        name={`projectPartTwo.projectResource.resourceRows[${index}].total`}
                      />

                      <Button shape="circle" icon={<CloseOutlined />} onClick={handleDelete(index)} />
                    </div>
                  )
                }
                )}
              </div>

              <Button type="link" onClick={handleAddRow}>
                + Добавить строку
              </Button>
            </>
          );
        }}
      </FieldArray>
    </div>
  </div>
);

export default ResourceTable;
