import React from 'react';
import { Typography } from 'antd';
import InputCustom from 'components/forms/Input';
import DatePickerCustom from 'components/forms/datePicker';
import { useFormikContext } from 'formik';
import s from './index.module.scss';

const { Title } = Typography;

const GeneralInfoGame = () => {
  const { values } = useFormikContext();

  return (
    <div>
      <Title level={4}>Общая информация</Title>

      <div className={s.wrap}>
        <div className={s.inputs}>
          <DatePickerCustom
            name="startDateTime"
            label="Дата проведения"
            placeholder="Выберите дату проведения"
            isShowTime
          />

          <InputCustom
            name="name"
            label="Название игры"
            descToolTip="Введите полное название игры"
            placeholder="Введите название"
          />
        </div>
      </div>
    </div>
  );
};

export default GeneralInfoGame;
