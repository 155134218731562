import * as Yup from 'yup';

export const INSTRUCTION_VALIDATION_SCHEMA = Yup.object().shape({

  instructionBasicInformation: Yup.object().shape({
    title: Yup.string().typeError('Введите строку').required('Обязательное поле'),
    description: Yup.string().typeError('Введите строку'),
    picturePath: Yup.string().typeError('Введите строку'),
    deadlines: Yup.string().typeError('Введите строку'),
  }),
  instructionDescription: Yup.object().shape({
    problemDescription: Yup.string().typeError('Введите строку'),
    solutionDescription: Yup.string().typeError('Введите строку'),
    analyticsDescription: Yup.string().typeError('Введите строку')
  }),
  instructionLimitations: Yup.object().shape({
    limitationDescription: Yup.string().typeError('Введите строку'),
    setOfRules: Yup.string().typeError('Введите строку'),
    precautionaryMeasuresDescription: Yup.string().typeError('Введите строку')
  }),
  instructionResources: Yup.object().shape({
    estimateDescription: Yup.string().typeError('Введите строку'),
    additionalExpenses: Yup.string().typeError('Введите строку'),
    necessaryTools: Yup.string().typeError('Введите строку'),
    instructionEstimateRows: Yup.array().of(
      Yup.object().shape({
        averagePrice: Yup.string().typeError('Введите строку'),
      })
    )
  }),
  instructionSteps: Yup.object().shape({
    stepsDescription: Yup.string().typeError('Введите строку'),
    instructionStepItems: Yup.array().of(
      Yup.object().shape({
        title: Yup.string().typeError('Введите строку'),
        description: Yup.string().typeError('Введите строку')
      })
    ),
    resultDescription: Yup.string().typeError('Введите строку'),
    resultSources: Yup.string().typeError('Введите строку')
  }),
  instructionExamples: Yup.object().shape({
    instructionExampleItems: Yup.array().of(
      Yup.object().shape({
        title: Yup.string().typeError('Введите строку'),
        shortDescription: Yup.string().typeError('Введите строку'),
        source: Yup.string().typeError('Введите строку')
      })
    )
  })
});

export const GAME_VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string().typeError('Введите строку'),
  startDateTime: Yup.string().typeError('Выберите дату')
});


export const APPLICATION_VALIDATION_SCHEMA = Yup.object().shape({

  projectPartOne: Yup.object().shape({
    title: Yup.string().typeError('Введите строку').required('Обязательное поле'),
    calls: Yup.string().typeError('Введите строку'),
    picturePath: Yup.string().typeError('Введите строку'),
    shortTitle: Yup.string().typeError('Введите строку'),
  })
});
