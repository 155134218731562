import Instructions from 'modules/instructions/instructions.module';
import InstructionDetail from 'modules/instructionDetail/instructionDetail.module';
import GameDetail from 'modules/GameDetail/gameDetail.module';
import Games from 'modules/Games/games.module';
import ProjectApplications from 'modules/ProjectApplications/projectApplications.module';
import Projects from 'modules/projects/projects.module';
import ApplicationDetail from 'modules/ApplicatonDetails/applicationDetail.module';
import { Container } from 'modules/containter.module';

export const combineModules = new Container()
  .subscribe('/', Projects)
  .subscribe('/instructions', Instructions)
  .subscribe('/instructions-create', InstructionDetail)
  .subscribe('/games-create', GameDetail)
  .subscribe('/games', Games)
  .subscribe('/projects', ProjectApplications)
  .subscribe('/application-create', ApplicationDetail);

console.log('combineModules===>', combineModules);

export const routes = [
  // {
  //   path: '/',
  //   name: 'projects'
  // },
  {
    path: '/',
    name: 'instructions'
  },
  {
    path: '/instructions/:id',
    name: 'instructionsDetail'
  },
  {
    path: '/instructions-create',
    name: 'instructionsDetail'
  },
  {
    path: '/projects/:id',
    name: 'applicationDetails'
  },
  {
    path: '/application-create',
    name: 'applicationDetails'
  },
  {
    path: '/games-create',
    name: 'gamesDetail'
  },
  {
    path: '/games',
    name: 'games'
  },
  {
    path: '/projects',
    name: 'projectApplications'
  }
];
