import React from 'react';
import { roles } from 'constans/ROLES';
import Header from 'components/header';
import Menu from 'components/menu';
import ContentInstructions from 'modules/instructions/components/content';
import reducer from 'modules/instructions/redux/reducers/instructions';
import { instructionsListSaga } from 'modules/instructions/redux/saga';
import Access from 'pages/access';

class Instructions {
  constructor() {
    this.permissions = {
      content: {
        administrator: true,
        moderator: true
      }
    };
    this.name = 'instructions';
    this.role = roles.administrator;
    this.header = (props) => <Header {...props} />;
    this.menu = (props) => <Menu {...props} />;
    this.content = (props) => (
      // eslint-disable-next-line react/no-this-in-sfc
      <Access permissions={this.permissions.content} denied={<></>}>
        <ContentInstructions {...props} />
      </Access>
    );
    this.reducer = () => ({ instructions: reducer });
    this.sagas = () => [instructionsListSaga];
  }
}

export default new Instructions();
