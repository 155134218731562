import React from 'react';
import { Field } from 'formik';
import { Input, Tooltip, Typography } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import s from './index.module.scss';

const { Text } = Typography;

const InputCustom = (props) => {
  const { label = '', name, descToolTip, className = s.field, placeholder } = props;

  return (
    <Field {...props}>
      {(formik) => {
        const { field, meta } = formik;
        const isError = !!(meta.error && meta.touched);
        const errorClass = isError ? s.error : '';

        return (
          <div className={className}>
            {label && (
              <Tooltip title={descToolTip}>
                <label htmlFor={name} className={s.label}>
                  <Text type="secondary" className={s.text}>
                    {label}
                  </Text>
                  {descToolTip && <QuestionCircleOutlined />}
                </label>
              </Tooltip>
            )}
            <Input
              {...field}
              id={name}
              className={`${s.input} ${errorClass}`}
              placeholder={placeholder}
            />

            {isError && (
              <label htmlFor={name}>
                <Text type="danger">{meta.error}</Text>
              </label>
            )}
          </div>
        );
      }}
    </Field>
  );
};

export default InputCustom;
