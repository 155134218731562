import { call, put, takeEvery } from 'redux-saga/effects';
import SERVICE_API from 'api';
import { DETAIL_GAME_ACTION_TYPES } from 'modules/GameDetail/redux/reducers/detailGames';
import {
  createSuccessGameAC,
  detailErrorAC
} from 'modules/GameDetail/redux/actions/actionsDetailGames';

function* createGame({ payload }) {
  try {
    yield call(SERVICE_API.GamesAPI.createGame, payload);
    yield put(createSuccessGameAC());
  } catch ({ response }) {
    yield put(detailErrorAC(response.data));
  }
}

export function* detailGameSaga() {
  yield takeEvery(DETAIL_GAME_ACTION_TYPES.START_CREATE, createGame);
}
