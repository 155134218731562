/* eslint-disable no-extra-boolean-cast,react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Divider, Tooltip, Typography } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import RadioCustom from 'components/forms/radio';
import { useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';
import { geoStartAC } from 'redux/actions/geoActions';
import ExampleInfo from './exampleInfo';
import s from '../index.module.scss';

const { Text } = Typography;

const ExampleInstruction = () => {
  const dispatch = useDispatch();
  const { values } = useFormikContext();
  const exampleValue = values?.instructionExamples?.implemented;

  const generatePractic = () => (exampleValue === 'true' ? <ExampleInfo /> : '');

  useEffect(() => {
    dispatch(geoStartAC());
  }, []);

  return (
    <div className={s.examples}>
      <Divider orientation="left">Примеры</Divider>

      <Tooltip title="Можно ли выделить регион (-ны), в которых практика уже реализована?">
        <Text type="secondary" className={s.textDesc}>
          Можно ли выделить регион (-ны), в которых практика уже реализована?
        </Text>
        <QuestionCircleOutlined />
      </Tooltip>

      <div className={s.radioGroup}>
        <RadioCustom
          name="instructionExamples.implemented"
          value="true"
          label="да"
          isChecked={exampleValue === 'true'}
        />
        <RadioCustom
          name="instructionExamples.implemented"
          value=""
          label="нет"
          isChecked={exampleValue === ''}
        />
      </div>

      {generatePractic()}
    </div>
  );
};

export default ExampleInstruction;
