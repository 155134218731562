import { DETAIL_GAME_ACTION_TYPES } from 'modules/GameDetail/redux/reducers/detailGames';

export const detailStartAC = (payload) => ({
  type: DETAIL_GAME_ACTION_TYPES.GET.START,
  payload
});
export const detailSuccessAC = () => ({ type: DETAIL_GAME_ACTION_TYPES.GET.SUCCESS });
export const detailErrorAC = (payload) => ({
  type: DETAIL_GAME_ACTION_TYPES.GET.ERROR,
  payload
});
export const detailSetDataAC = (payload) => ({
  type: DETAIL_GAME_ACTION_TYPES.SET_DETAIL,
  payload
});
export const createStartGameAC = (payload) => ({
  type: DETAIL_GAME_ACTION_TYPES.START_CREATE,
  payload
});
export const updateStartGameAC = (payload) => ({
  type: DETAIL_GAME_ACTION_TYPES.START_UPDATE,
  payload
});
export const createSuccessGameAC = () => ({
  type: DETAIL_GAME_ACTION_TYPES.SUCCESS_CREATE
});

export const resetStateGame = () => ({ type: DETAIL_GAME_ACTION_TYPES.RESET_STATE });
