import React from 'react';
import { Divider } from 'antd';
import DatePickerCustom from 'components/forms/datePicker';
import SelectCustom from 'components/forms/Select';
import { statusList } from 'constants/LISTS_SELECT';
import s from './index.module.scss';

const SideInfo = () => (
  <div className={s.info}>
    <Divider orientation="left">Информация</Divider>

    <DatePickerCustom
      name="publicationTime"
      label="Дата публикации"
      placeholder="Выберите дату публикации"
    />
    <SelectCustom
      name="resourceStatus"
      list={statusList}
      placeholder="Выберите статус"
      label="Статус "
    />
  </div>
);

export default SideInfo;
