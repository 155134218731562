import { APPLICATIONS_ACTION_TYPES } from 'modules/ProjectApplications/redux/reducers/projectApplicationsReducers';

export const applicationStartAC = () => ({ type: APPLICATIONS_ACTION_TYPES.GET.START });

export const applicationSuccessAC = () => ({ type: APPLICATIONS_ACTION_TYPES.GET.SUCCESS });
export const applicationErrorAC = (payload) => ({
    type: APPLICATIONS_ACTION_TYPES.GET.ERROR,
    payload
});
export const applicationSetListAC = (payload) => ({
    type: APPLICATIONS_ACTION_TYPES.SET_APPLICATIONS,
    payload
});
export const applicationStartDeleteAC = (payload) => ({
    type: APPLICATIONS_ACTION_TYPES.START_DELETE,
    payload
});

export const applicationStartChangeStatus = (payload) => ({
    type: APPLICATIONS_ACTION_TYPES.START_CHANGE_STATUS,
    payload
});