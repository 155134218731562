/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Button, Divider } from 'antd';
import { FieldArray } from 'formik';
import { exampleData } from 'constants/TABLE_COLUMNS';
import ExampleItem from 'modules/instructionDetail/components/detailForm/exampleInstruction/exampleItem';
import s from 'modules/instructionDetail/components/detailForm/index.module.scss';

const ExampleInfo = () => (
  <div className={s.exmapleInfo}>
    <Divider orientation="left">Информация о практике</Divider>

    <FieldArray name="instructionExamples.instructionExampleItems">
      {({ push, remove, form }) => {
        const handleAdd = () => push(exampleData);
        const handleDelete = (index) => () => remove(index);

        return (
          <>
            <div className={s.examples}>
              {form?.values?.instructionExamples?.instructionExampleItems?.map((example, index) => (
                <ExampleItem
                  key={`${example.name}_${index}`}
                  index={index}
                  handleDelete={handleDelete}
                  data={example}
                />
              ))}
            </div>

            <Button type="link" onClick={handleAdd}>
              + Добавить пример
            </Button>
          </>
        );
      }}
    </FieldArray>
  </div>
);

export default ExampleInfo;
