import React from 'react';

import { Provider } from 'react-redux';
import Layout from 'components/layout';
import { store } from './redux';
import 'antd/dist/antd.css';
import 'assets/sass/index.scss';


function App() {
  return (
    <Provider store={store}>

      <Layout />


    </Provider>
  );
}

export default App;
