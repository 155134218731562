import React from 'react';
import { Button, Layout } from 'antd';
import Logo from 'components/logo';
import { colors } from 'constans/COLORS';
import ExitButton from 'components/exitButton';
import { Link } from 'react-router-dom';
import Styles from './index.module.scss';

const { Header } = Layout;

const MyHeader = () => (
  <Header className={Styles.header}>
    <div className={Styles.header__content}>
      <div className={Styles.logo}>
        <Logo color={colors.black} />
      </div>
      {/* <Button className={Styles.gameButton}>Игра</Button> */}
      {/* <Button type="primary">Платформа</Button> */}
    </div>
    {/* <Button className={Styles.settings}>...</Button> */}
    <ExitButton />
  </Header>
);
export default MyHeader;
