/* eslint-disable react/no-array-index-key */
import React from 'react';
import { FieldArray } from 'formik';
import { stepData } from 'constants/TABLE_COLUMNS';
import s from 'modules/instructionDetail/components/detailForm/index.module.scss';
import { Button, Divider } from 'antd';
import InputCustom from 'components/forms/Input';
import TextEdditor from 'components/forms/textEdditor';
import UploadImg from 'components/forms/uploadImg';
import { CloseOutlined } from '@ant-design/icons';

const StepList = () => (
  <FieldArray name="instructionSteps.instructionStepItems">
    {({ push, remove, form }) => {
      const handleAdd = () => push(stepData);
      const handleDelete = (index) => () => remove(index);

      return (
        <>
          <div className={s.steps}>
            {form?.values?.instructionSteps?.instructionStepItems?.map(
              ({ description, picturePath }, index) => (
                <div className={s.step} key={`step__${index}`}>
                  <Divider orientation="left">Этап {index + 1}</Divider>

                  <div className={s.stepContent}>
                    <div className={s.stepInputs}>
                      <InputCustom
                        label="Название этапа"
                        descToolTip="Название этапа"
                        name={`instructionSteps.instructionStepItems.${index}.title`}
                      />
                      <TextEdditor
                        name={`instructionSteps.instructionStepItems.${index}.description`}
                        label="Описание"
                        descToolTip="Должно включать описание идеи и ответы на вопросы: Почему проект востребован? Почему его нужно поддержать?,Какие положительные эффекты можно от этого получить
                            (можно вставлять иллюстрации в тексте) "
                        detailValue={description}
                      />
                    </div>
                    <UploadImg
                      name={`instructionSteps.instructionStepItems.${index}.picturePath`}
                      label="Обложка"
                      descToolTip="Добавьте изображение для карточки инструкции, которое пользователь увидит при про смотре списка"
                      detailPath={picturePath}
                    />

                    <Button shape="circle" icon={<CloseOutlined />} onClick={handleDelete(index)} />
                  </div>
                </div>
              )
            )}
          </div>

          <Button type="link" onClick={handleAdd}>
            + Добавить этап
          </Button>
        </>
      );
    }}
  </FieldArray>
);

export default StepList;
