
export const platformRoutes = [
  // {
  //   route: '/',
  //   title: 'Новости'
  // },
  {
    route: '/',
    title: 'Инструкции',
    breadcrumbName: 'Список инструкций'
  }
  // {
  //   route: '/projects',
  //   title: 'Проекты'
  // },
  // {
  //   route: '/tests',
  //   title: 'Тесты'
  // },
  // {
  //   route: '/users',
  //   title: 'Пользователи'
  // },
  // {
  //   route: '/moderators',
  //   title: 'Модераторы'
  // }
];

export const gameRoutes = [
  {
    route: '/games',
    title: 'Игры',
    breadcrumbName: 'Список игр'
  }
];

export const applicationRoutes = [
  {
    route: '/projects',
    title: 'Проекты',
    breadcrumbName: 'Список проектов'
  }
];
