/* eslint-disable arrow-body-style */
import React from 'react';
import { formatedDate } from 'utils/formatDate';
import StatusItem from 'components/status';

export const instructionsTransformDataTable = (data = []) =>
  data.reduce(
    (acc, cur) => [
      ...acc,
      {
        ...cur,
        key: cur.id,
        publicationTime: formatedDate(cur.publicationTime),
        resourceStatus: <StatusItem status={cur.resourceStatus} />,
        action: [
          {
            title: 'Изменить',
            isLink: true,
            link: `/instructions/${cur.id}`
          },
          {
            title: 'Удалить',
            value: cur.id
          }
        ]
      }
    ],
    []
  );

export const applicationTransformDataTable = (data = []) =>
  data.reduce(
    (acc, cur) => [
      ...acc,
      {
        ...cur,
        key: cur.id,
        publicationTime: formatedDate(cur.publicationTime),
        status: cur.resourceStatus,
        resourceStatus: <StatusItem status={cur.resourceStatus} />,
        action: [
          {
            title: 'Изменить',
            isLink: true,
            link: `/projects/${cur.id}`
          },
          {
            title: 'Изменить статус',
            value: cur.id
          },
          {
            title: 'Удалить',
            value: cur.id
          }
        ]
      }
    ],
    []
  );

export const transfromData = (data = []) =>
  data.reduce((acc, cur) => [...acc, { title: cur.value, value: cur.id }], []);

export const transformDataInstruction = (data) => {
  const {
    title,
    deadlines,
    description,
    picturePath,
    implementationCost,
    instructionCategories,
    instructionFinishingType,
    instructionComplexityImplementation
  } = data.instructionBasicInformation;
  const { implemented, instructionExampleItems } = data.instructionExamples;
  return {
    ...data,
    instructionBasicInformation: {
      title,
      deadlines,
      description,
      implementationCost,
      picturePath,
      instructionCategoriesId: instructionCategories?.map((el) => el?.id),
      instructionFinishingTypeId: instructionFinishingType?.id,
      instructionComplexityImplementationId: instructionComplexityImplementation?.id
    },
    instructionExamples: {
      ...data.instructionExamples,
      implemented: implemented ? 'true' : '',
      instructionExampleItems: instructionExampleItems?.map((el) => ({
        ...el,
        title: el?.name,
        countryId: el?.country?.id,
        regionId: el?.region?.id
      }))
    }
  };
};

export const transformDataSend = (data) => ({
  ...data,
  instructionExamples: {
    ...data.instructionExamples,
    implemented: Boolean(data.instructionExamples.implemented),
    instructionExampleItems: data.instructionExamples.instructionExampleItems.map((el) => ({
      ...el,
      name: el.title
    }))
  }
});

export const transformGameDataSend = (data) => ({
  name: data.name,
  startDateTime: data.startDateTime
});


export const transformApplicationDatabeforeSend = (data) => ({ ...data });




// ////////////////////////////////////////////////

export const transformProjectApplicatiomData = (data) => {

  console.log('data-from-transformProjectApplicatiomData', data)

  const {
    projectPartOne,
    projectPartTwo,
    projectPartThree
  } = data;

  return {
    projectPartOne,
    projectPartTwo,
    projectPartThree
  };
};