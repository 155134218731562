/* eslint-disable arrow-body-style */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Tooltip, Typography, Button } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { FieldArray, useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { getFilePath, getIsLoadingFile } from 'redux/selectors';
import UploadImgNoLabel from './uploadComponent';
import s from './index.module.scss';

const { Text } = Typography;

const FieldArrayMultipleUpploads = ({ name, label, descToolTip, addButtonTitle, removeButtonTitle }) => {
    //   const dispatch = useDispatch();
    //   const { setFieldValue } = useFormikContext();
    //   const filePath = useSelector(getFilePath(name));
    //   const isLoading = useSelector(getIsLoadingFile);
    //   const imgPreview = filePath || detailPath;



    return (
        <FieldArray name={name}>

            {({ push, remove, form }) => {

                const sectionName = name.split('.')[0];
                const fiedlName = name.split('.')[1];
                const list = form.values[sectionName][fiedlName];


                return (
                    <div className={s.field}>
                        {label && (
                            <Tooltip title={descToolTip}>
                                <label htmlFor={name} className={s.label}>
                                    <Text type="secondary" className={s.text}>
                                        {label}
                                    </Text>
                                    {descToolTip && <QuestionCircleOutlined />}
                                </label>
                            </Tooltip>
                        )}



                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <Button type='primary' ghost onClick={() => push({ filePath: "" })} style={{ marginBottom: '1rem', alignSelf: 'center' }}>{addButtonTitle}</Button>

                            {
                                list.map((listItem, index) =>
                                    <div key={index} style={{ marginBottom: '1rem', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                        <UploadImgNoLabel
                                            name={`${name}[${index}].filePath`}
                                            // label="Приложения"
                                            // descToolTip="Прикрепите дополнительные материалы, которые помогут другим участникам и экспертам понять ваш проект, например, чертежи для изготовления деталей, образцы заявлений, фотографии, дорожные карты, исследования"
                                            detailPath={list[index].filePath}
                                        />
                                        {index > 0 && <Button type='primary' onClick={() => remove(index)} style={{ marginTop: '0.5rem', width: 'auto' }}>{removeButtonTitle}</Button>}
                                    </div>

                                )
                            }
                        </div>

                    </div>
                )
            }}



        </FieldArray>
    );
};

export default FieldArrayMultipleUpploads 
