import React from 'react';
import { Divider, Typography } from 'antd';
import EditableTable from 'modules/instructionDetail/components/editableTable';
import TextEdditor from 'components/forms/textEdditor';
import { useFormikContext } from 'formik';
import s from './index.module.scss';

const { Title } = Typography;

const ResourceInstruction = () => {
  const { values } = useFormikContext();

  const { additionalExpenses, estimateDescription, necessaryTools } = values.instructionResources;

  return (
    <div className={s.resource}>
      <Title level={4}>Ресурсы</Title>

      <Divider orientation="left">Смета</Divider>

      <EditableTable />

      <TextEdditor
        className={s.textarea}
        name="instructionResources.estimateDescription"
        label="Описание"
        detailValue={estimateDescription}
      />

      <div className={s.wrap}>
        <div className={s.wrapBlock}>
          <Divider orientation="left">Дополнительные затраты</Divider>

          <TextEdditor
            className={s.textarea}
            name="instructionResources.additionalExpenses"
            label="Описание"
            detailValue={additionalExpenses}
          />
        </div>

        <div className={s.wrapBlock}>
          <Divider orientation="left">Необходимый инструмент</Divider>

          <TextEdditor
            className={s.textarea}
            name="instructionResources.necessaryTools"
            label="Описание"
            detailValue={necessaryTools}
          />
        </div>
      </div>



    </div>
  );
};

export default ResourceInstruction;
