import { stepData } from 'constants/TABLE_COLUMNS';

export const initialValuesInstruction = {
  publicationTime: 0,
  resourceStatus: '',
  instructionBasicInformation: {
    title: '',
    description: '',
    picturePath: '',
    deadlines: '',
    implementationCost: '1',
    instructionComplexityImplementationId: ''
  },
  instructionDescription: {
    problemDescription: '',
    solutionDescription: '',
    solutionPicturePath: '',
    analyticsDescription: '',
    analyticsPicturePath: ''
  },
  instructionLimitations: {
    limitationDescription: '',
    setOfRules: '',
    precautionaryMeasuresDescription: '',
    precautionaryMeasuresPicturePath: ''
  },
  instructionResources: {
    estimateDescription: '',
    instructionEstimateRows: [],
    additionalExpenses: '',
    necessaryTools: ''
  },
  instructionSteps: {
    stepsDescription: '',
    instructionStepItems: [stepData],
    resultDescription: '',
    resultPicturePath: '',
    resultSources: ''
  },
  instructionExamples: {
    implemented: '',
    instructionExampleItems: []
  }
};


export const projectApplicationInitialValues = {

  projectPartOne:
  {
    coordinates: "",
    title: "",
    call: "",
    shortTitle: "",
    picturePath: "",
  },
  projectPartTwo:
  {
    description: "",
    restrictions: "",
    projectResource: {
      descriptionBefore: "",
      resourceRows: [
        {
          material: "",
          unit: "",
          number: "",
          price: "",
          total: ""
        }
      ],
      descriptionAfter: ""
    },
    minimumVersion: "",
    implementationCost: "",
    additionalFeatures: "",
    procedure: "",
    projectApplications: [
      {
        filePath: ""
      }
    ],
    sources: "",
  },
  projectPartThree:
  {
    examples: "",
    projectCategoriesId: [],
    results: "",
    indicators: "",
    text: "",
    projectComplexityImplementationId: "",
    implementationPeriodDays: 0,
    quoteText: "",
    quoteAuthor: ""
  }
};
