import HOR from 'redux/HOR';
import { ActionTypes } from 'redux/utils/actionCreator';

const { pipeHigherOrderReducers, withLoadable, withResetState } = HOR;
export const MENU_ACTION_TYPES = new ActionTypes('MENU', ['SET_IS_PLATFORM', 'SET_ACTIVE_BUTTON'])
  .listAT()
  .getActionTypes();

const initialState = {
  isPlatform: true,
  activeButton: 1
};
const handleAction = {
  [MENU_ACTION_TYPES.SET_IS_PLATFORM]: (state, params) => ({
    ...state,
    isPlatform: params
  }),
  [MENU_ACTION_TYPES.SET_ACTIVE_BUTTON]: (state, params) => ({
    ...state,
    activeButton: params
  })
};

const reducer = (state = initialState, action) =>
  handleAction[action.type] ? handleAction[action.type](state, action.payload) : state;

export default pipeHigherOrderReducers(
  withResetState(MENU_ACTION_TYPES.RESET_STATE, initialState),
  withLoadable({
    isLoadingActionType: [MENU_ACTION_TYPES.GET.START],
    successActionType: [MENU_ACTION_TYPES.GET.SUCCESS],
    errorActionType: [MENU_ACTION_TYPES.GET.ERROR]
  })
)(reducer);
