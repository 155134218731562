import HOR from 'redux/HOR';
import { ActionTypes } from 'redux/utils/actionCreator';

const { pipeHigherOrderReducers, withLoadable, withResetState } = HOR;

export const INSTRUCTIONS_ACTION_TYPES = new ActionTypes('INSTRUCTIONS', [
  'SET_INSTRUCTIONS',
  'START_DELETE'
])
  .listAT()
  .getActionTypes();

const initialState = {
  list: [],
  totalAmount: 0
};

const handleAction = {
  [INSTRUCTIONS_ACTION_TYPES.SET_INSTRUCTIONS]: (state, params) => {
    console.log('params', params);
    return {
      ...state,
      list: [...params.list]
    };
  }
};

const reducer = (state = initialState, action) =>
  handleAction[action.type] ? handleAction[action.type](state, action.payload) : state;

export default pipeHigherOrderReducers(
  withResetState(INSTRUCTIONS_ACTION_TYPES.RESET_STATE, initialState),
  withLoadable({
    isLoadingActionType: [
      INSTRUCTIONS_ACTION_TYPES.GET.START,
      INSTRUCTIONS_ACTION_TYPES.START_DELETE
    ],
    successActionType: [INSTRUCTIONS_ACTION_TYPES.GET.SUCCESS],
    errorActionType: [INSTRUCTIONS_ACTION_TYPES.GET.ERROR]
  })
)(reducer);
