import UserAPI from './user';
import InstructionsAPI from './instructions';
import GamesAPI from './games';
import UtilsApi from './utils';
import ApplicationsAPI from './applications/applicationsApi';

const SERVICE_API = {
  UserAPI,
  InstructionsAPI,
  GamesAPI,
  UtilsApi,
  ApplicationsAPI
};

export default SERVICE_API;
