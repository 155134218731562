import createSagaMiddleware from 'redux-saga';
import { applyMiddleware, compose, createStore } from 'redux';
import rootSaga from 'redux/saga';
import appReducer from 'redux/store';

const isDevelopmentMode = process.env.REACT_APP_DEVELOPMENT;

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = isDevelopmentMode ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    trace: true,
    traceLimit: 25
}) : compose;

export const store = createStore(appReducer, {}, composeEnhancers(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(rootSaga);
