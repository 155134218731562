// import React from 'react';
// import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// import PrivateRoute from 'components/router/PrivateRoute';
// import Admin from 'pages/admin';
// import NoPage from 'pages/noPage';
// import { combineModules, routes } from 'modules/combineModules';

// const Layout = () => (
//   <Router basename={process.env.REACT_APP_ROUTE_PREFIX || ''}>
//     <Switch>
//       {routes.map((route) => (
//         <PrivateRoute
//           exact
//           key={route.path}
//           path={route.path}
//           render={() => <Admin component={combineModules.activeModule(route.name)} />}
//         />
//       ))}
//       <Route exact path="*" component={NoPage} />
//     </Switch>
//   </Router>
// );

// export default Layout;
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PrivateRoute from 'components/router/PrivateRoute';
import Admin from 'pages/admin';
import NoPage from 'pages/noPage';
import { combineModules, routes } from 'modules/combineModules';

const Layout = () => (
  <Router basename={process.env.REACT_APP_ROUTE_PREFIX || ''}>
    <Switch>
      {routes.map((route) => (
        <PrivateRoute
          exact
          key={route.path}
          path={route.path}
          render={() => <Admin component={combineModules.activeModule(route.name)} />}
        />
      ))}
      <Route exact path="*" component={NoPage} />
    </Switch>
  </Router>
);

export default Layout;
