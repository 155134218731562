import React from 'react';
import { Layout } from 'antd';
import Download from 'components/download';
import Styles from './index.module.scss';

const Admin = ({ component }) => {


  const { module } = component;



  if (module)
    return (
      <section className={Styles.admin}>
        <Layout>{module.header()}</Layout>
        <Layout>
          {module.menu()}
          <Layout>{module.content()}</Layout>
        </Layout>
      </section>
    );

  return <Download />;
};

export default Admin;
