import HOR from 'redux/HOR';
import { ActionTypes } from 'redux/utils/actionCreator';

const { pipeHigherOrderReducers, withLoadable, withResetState } = HOR;

export const DETAIL_GAME_ACTION_TYPES = new ActionTypes('DETAIL_GAME', [
  'SET_DETAIL',
  'START_CREATE',
  'SUCCESS_CREATE',
  'START_UPDATE'
])
  .listAT()
  .getActionTypes();

const initialState = {
  data: {}
};

const handleAction = {
  [DETAIL_GAME_ACTION_TYPES.SET_DETAIL]: (state, params) => ({
    ...state,
    data: params
  }),
  [DETAIL_GAME_ACTION_TYPES.SUCCESS_CREATE]: (state) => ({
    ...state,
    isLoading: false,
    isSuccess: true,
    error: null
  }),
  [DETAIL_GAME_ACTION_TYPES.START_CREATE]: (state) => ({
    ...state,
    isLoading: true,
    isSuccess: false,
    error: null
  }),
  [DETAIL_GAME_ACTION_TYPES.START_UPDATE]: (state) => ({
    ...state,
    isLoading: true,
    isSuccess: false,
    error: null
  })
};

const reducer = (state = initialState, action) =>
  handleAction[action.type] ? handleAction[action.type](state, action.payload) : state;

export default pipeHigherOrderReducers(
  withResetState(DETAIL_GAME_ACTION_TYPES.RESET_STATE, initialState),
  withLoadable({
    isLoadingActionType: [DETAIL_GAME_ACTION_TYPES.GET.START],
    successActionType: [DETAIL_GAME_ACTION_TYPES.GET.SUCCESS],
    errorActionType: [DETAIL_GAME_ACTION_TYPES.GET.ERROR]
  })
)(reducer);
