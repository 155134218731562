/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Typography, Upload } from 'antd';
import { Field, useFormikContext } from 'formik';
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';
import { fileStartAC, resetFileAC } from 'redux/actions/file';
import { useDispatch, useSelector } from 'react-redux';
import { getFilePath, getIsLoadingFile } from 'redux/selectors';
import s from './index.module.scss';

const { Text } = Typography;

const UploadImgNoLabel = ({ name, detailPath }) => {
    const dispatch = useDispatch();
    const { setFieldValue } = useFormikContext();
    const filePath = useSelector(getFilePath(name));
    const isLoading = useSelector(getIsLoadingFile);
    const imgPreview = filePath || detailPath;

    useEffect(() => () => dispatch(resetFileAC()), []);

    useEffect(() => {
        if (filePath) {
            setFieldValue(name, filePath);
        }
    }, [filePath]);

    const handleUpload = (file) => {


        dispatch(fileStartAC({ file, detailPath: imgPreview, name }));

        return false;
    };

    return (
        <Field>
            {() => (
                <div className={s.field}>
                    <Upload
                        listType="picture-card"
                        name={name}
                        beforeUpload={handleUpload}
                        className={s['avatar-uploader']}
                        showUploadList={false}
                        disabled={isLoading}
                    >
                        {imgPreview ? (
                            <img
                                src={`${process.env.REACT_APP_API_URL}${imgPreview}`}
                                alt=""
                                style={{ width: '100%', height: '100%' }}
                            />
                        ) : (
                            <div>
                                <PlusOutlined />
                                <div style={{ marginTop: 8 }}>Upload</div>
                            </div>
                        )}
                    </Upload>
                </div>
            )}
        </Field>
    );
};

export default UploadImgNoLabel;