/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Button, Tooltip } from 'antd';
import { CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { FieldArray } from 'formik';
import { columnsData, rowEstimateData } from 'constants/TABLE_COLUMNS';
import InputCustom from 'components/forms/Input';
import s from './index.module.scss';

const EditableTable = () => (
  <div className={s.table}>
    <div className={s.header}>
      {columnsData.map(({ title, key, descToolTip }, index) => (
        <div className={s.column} key={`${key}_${index}`}>
          <Tooltip title={descToolTip} className={s.tooltip}>
            <div>{title}</div>
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
      ))}
    </div>
    <div className={s.body}>
      <FieldArray name="instructionResources.instructionEstimateRows">
        {({ remove, push, form }) => {
          const handleDelete = (index) => () => remove(index);
          const handleAddRow = () => push(rowEstimateData);
          return (
            <>
              <div className={s.rows}>
                {form?.values?.instructionResources?.instructionEstimateRows?.map((_, index) => (
                  <div className={s.row}>
                    <InputCustom
                      name={`instructionResources.instructionEstimateRows.${index}.material`}
                    />
                    <InputCustom
                      name={`instructionResources.instructionEstimateRows.${index}.averagePrice`}
                    />
                    <InputCustom
                      name={`instructionResources.instructionEstimateRows.${index}.number`}
                    />
                    <InputCustom
                      name={`instructionResources.instructionEstimateRows.${index}.total`}
                    />

                    <Button shape="circle" icon={<CloseOutlined />} onClick={handleDelete(index)} />
                  </div>
                ))}
              </div>

              <Button type="link" onClick={handleAddRow}>
                + Добавить строку
              </Button>
            </>
          );
        }}
      </FieldArray>
    </div>
  </div>
);

export default EditableTable;
