/* eslint-disable no-eval */
import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Tooltip, Typography } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Field } from 'formik';
// eslint-disable-next-line no-unused-vars
import tinymce from 'tinymce/tinymce'; // DONT TOUCH THIS STRING, THIS IS SELFHOST FOR EDITOR!!!
import s from './index.module.scss';

const { Text } = Typography;

const TextEdditor = ({ name, detailValue, label, descToolTip, className = s.textarea }) => (
  <Field>
    {({ form, meta }) => {
      const isError = !!(meta.error && meta.touched);
      const handleChange = (value) => form.setFieldValue(name, value);
      return (
        <div className={className}>
          {label && (
            <Tooltip title={descToolTip}>
              <label htmlFor={name} className={s.label}>
                <Text type="secondary" className={s.text}>
                  {label}
                </Text>
                {descToolTip && <QuestionCircleOutlined />}
              </label>
            </Tooltip>
          )}

          <Editor
            init={{
              menubar: true,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount',
                'image',
                'table'
              ],
              toolbar: `undo redo | bold italic backcolor | 
          alignleft aligncenter alignright alignjustify | 
          bullist numlist outdent indent | image `
            }}
            onEditorChange={handleChange}
            value={detailValue || ''}
          />

          {isError && (
            <label htmlFor={name}>
              <Text type="danger">{meta.error?.value}</Text>
            </label>
          )}
        </div>
      );
    }}
  </Field>
);

export default TextEdditor;
