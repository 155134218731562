import { call, put, takeEvery } from 'redux-saga/effects';
import SERVICE_API from 'api';
import { INFO_GAMES_ACTION_TYPES } from 'modules/GameDetail/redux/reducers/infoGames';
import {
  infoSetStatusesAC,
  infoSuccessAC,
  infoErrorAC
} from 'modules/GameDetail/redux/actions/actionsInfoGames';

function* getInfoGame() {
  try {
    const statuses = yield call(SERVICE_API.UtilsApi.getStatuses);

    yield put(infoSetStatusesAC(statuses.data));

    yield put(infoSuccessAC());
  } catch ({ response }) {
    yield put(infoErrorAC(response.data));
  }
}

export function* infoGameSaga() {
  yield takeEvery(INFO_GAMES_ACTION_TYPES.GET.START, getInfoGame);
}
