import API from 'api/restApiService';

class ApplicationsAPI {
    // получить список проектов для админ панели
    getApplicationsList = (payload) => API.get('/api/project-admin', payload);

    // получить информацию о проекте для админ панели
    getApplicationDetail = (id) => API.get(`/api/project-admin/${id}`);

    // создать проект
    createApplication = (payload) => API.post('/api/project-admin', payload);

    // изменить проект 
    updateApplication = ({ id, data }) => API.put(`/api/project-admin/${id}`, data);

    // удалить проект 
    deleteApplication = (id) => API.delete(`/api/project-admin/${id}`);

    // получить список категорий
    applicationGetCategories = () => API.get('/api/projects/categories')

    // получить список сложностей
    applicationGetComplexities = () => API.get('/api/projects/complexity-implementation')

    // поменять статус
    applicationChangeStatus = (id) => API.put(`/api/project-admin/resource-status/${id}`)
}

export default new ApplicationsAPI();