import { FILE_ACTION_TYPES } from 'redux/reducers/files';
import { call, put, takeEvery } from 'redux-saga/effects';
import SERVICE_API from 'api';
import { fileErrorAC, fileSuccessAC, setFileAC } from 'redux/actions/file';

function* postFile({ payload }) {
  try {
    console.log('payload', payload)
    const formData = new FormData();
    formData.append('file', payload.file);
    if (payload.detailPath) {
      yield SERVICE_API.UtilsApi.deleteFile({ relativePath: payload.detailPath });
    }

    console.log('formData', formData)

    const { data } = yield call(SERVICE_API.UtilsApi.postUploadFile, formData);
    yield put(setFileAC({ [payload.name]: data.relativePath }));
    yield put(fileSuccessAC());
  } catch ({ response }) {
    yield put(fileErrorAC());
  }
}

export function* fileSaga() {
  yield takeEvery(FILE_ACTION_TYPES.GET.START, postFile);
}
