import HOR from 'redux/HOR';
import { ActionTypes } from 'redux/utils/actionCreator';

const { pipeHigherOrderReducers, withLoadable, withResetState } = HOR;

export const GAMES_ACTION_TYPES = new ActionTypes('GAMES', [
  'SET_GAMES',
  'START_DELETE'
])
  .listAT()
  .getActionTypes();

const initialState = {
  list: [],
  totalAmount: 0
};

const handleAction = {
  [GAMES_ACTION_TYPES.SET_INSTRUCTIONS]: (state, params) => ({
    ...state,
    ...params
  })
};

const reducer = (state = initialState, action) => {
  console.log('action', action.type)
  return handleAction[action.type] ? handleAction[action.type](state, action.payload) : state;
}

export default pipeHigherOrderReducers(
  withResetState(GAMES_ACTION_TYPES.RESET_STATE, initialState),
  withLoadable({
    isLoadingActionType: [
      GAMES_ACTION_TYPES.GET.START,
      GAMES_ACTION_TYPES.START_DELETE
    ],
    successActionType: [GAMES_ACTION_TYPES.GET.SUCCESS],
    errorActionType: [GAMES_ACTION_TYPES.GET.ERROR]
  })
)(reducer);
