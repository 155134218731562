/* eslint-disable arrow-body-style */
import HOR from 'redux/HOR';
import { ActionTypes } from 'redux/utils/actionCreator';

const { pipeHigherOrderReducers, withLoadable, withResetState } = HOR;

export const DETAIL_INSTRUCTION_ACTION_TYPES = new ActionTypes('DETAIL_INSTRUCTION', [
  'SET_DETAIL',
  'START_CREATE',
  'SUCCESS_CREATE',
  'START_UPDATE'
])
  .listAT()
  .getActionTypes();

const initialState = {
  data: {}
};

const handleDetailAction = {
  [DETAIL_INSTRUCTION_ACTION_TYPES.SET_DETAIL]: (state, params) => {
    return {
      ...state,
      data: params
    };
  },
  [DETAIL_INSTRUCTION_ACTION_TYPES.SUCCESS_CREATE]: (state) => ({
    ...state,
    isLoading: false,
    isSuccess: true,
    error: null
  }),
  [DETAIL_INSTRUCTION_ACTION_TYPES.START_CREATE]: (state) => ({
    ...state,
    isLoading: true,
    isSuccess: false,
    error: null
  }),
  [DETAIL_INSTRUCTION_ACTION_TYPES.START_UPDATE]: (state) => ({
    ...state,
    isLoading: true,
    isSuccess: false,
    error: null
  })
};

const detailReducer = (state = initialState, action) => {
  return handleDetailAction[action.type]
    ? handleDetailAction[action.type](state, action.payload)
    : state;
};

export default pipeHigherOrderReducers(
  withResetState(DETAIL_INSTRUCTION_ACTION_TYPES.RESET_STATE, initialState),
  withLoadable({
    isLoadingActionType: [DETAIL_INSTRUCTION_ACTION_TYPES.GET.START],
    successActionType: [DETAIL_INSTRUCTION_ACTION_TYPES.GET.SUCCESS],
    errorActionType: [DETAIL_INSTRUCTION_ACTION_TYPES.GET.ERROR]
  })
)(detailReducer);
