/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Checkbox, Col, Row, Tooltip, Typography } from 'antd';
import { Field, useFormikContext } from 'formik';
import { QuestionCircleOutlined } from '@ant-design/icons';
import s from './index.module.scss'

// const onChange = (checkValues = [], valuesField, initialValues = []) => {

//   initialValues = [...checkValues];
//   console.log('checkValues===>', checkValues)
//   valuesField = initialValues
// }
const { Text } = Typography;

const CheckboxGroup = ({ name, checkboxGroupOptions, label, descToolTip, spanSize = 8, groupWidth = '100%', className }) => {

  const form = useFormikContext();
  const precheckedOptionsFromBack = useSelector(state => state.applictionDetails?.data?.projectPartThree?.projectCategories);
  const changedArr = precheckedOptionsFromBack?.map((category) => category.id)
  const [precheckedOptions, setOptions] = useState(null);

  useEffect(() => {
    if (changedArr?.length > 0) setOptions(changedArr)
  }, [precheckedOptionsFromBack])

  useEffect(() => {
    form.setFieldValue(name, precheckedOptions)
  }, [precheckedOptions])



  const onChange = (checkedValues) => {
    setOptions(checkedValues)
  };


  return (

    <div className={className}>
      {label && (
        <Tooltip title={descToolTip}>
          <label htmlFor={name} className={s.label}>
            <Text type="secondary" className={s.text}>
              {label}
            </Text>
            {descToolTip && <QuestionCircleOutlined />}
          </label>
        </Tooltip>
      )}

      <Checkbox.Group
        options={checkboxGroupOptions}
        style={{ width: `${groupWidth}` }}
        onChange={onChange}

        value={precheckedOptions}
      />

    </div>

  )
};

export default CheckboxGroup;