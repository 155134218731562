import React from 'react';
import StepInstruction from './stepInstruction';
import GeneralInfoInstruction from './generalInfoInstruction';
import DescriptionInstruction from './descriptionInstruction';
import LimitsInstruction from './limitsInstruction';
import ResourceInstruction from './resourceInstruction';
import ExampleInstruction from './exampleInstruction';
import s from './index.module.scss';

const InstructionDetailForm = () => (
  <div className={s.content}>
    <GeneralInfoInstruction />
    <DescriptionInstruction />
    <LimitsInstruction />
    <ResourceInstruction />
    <StepInstruction />
    <ExampleInstruction />
  </div>
);

export default InstructionDetailForm;
