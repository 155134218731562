import React from 'react';
import { Divider } from 'antd';
import TextEdditor from 'components/forms/textEdditor';
import { useFormikContext } from 'formik';
import UploadImg from 'components/forms/uploadImg';
import s from 'modules/instructionDetail/components/detailForm/index.module.scss';
import StepList from './steps';

const StepInstruction = () => {
  const { values } = useFormikContext();

  const {
    stepsDescription,
    resultDescription,
    resultSources,
    resultPicturePath
  } = values.instructionSteps;

  return (
    <div className={s.step}>
      <Divider orientation="left">Этапы</Divider>

      <TextEdditor
        className={s.textarea}
        name="instructionSteps.stepsDescription"
        label="Описание"
        detailValue={stepsDescription}
      />

      <StepList />

      <Divider orientation="left">Итог</Divider>

      <div className={s.total}>
        <div>
          <TextEdditor
            className={s.textarea}
            name={`instructionSteps.resultDescription`}
            label="Описание"
            detailValue={resultDescription}
          />

          <TextEdditor
            className={s.textarea}
            name={`instructionSteps.resultSources`}
            label="Источники"
            detailValue={resultSources}
          />
        </div>
        <UploadImg
          name={`instructionSteps.resultPicturePath`}
          label="Обложка"
          descToolTip="Добавьте изображение для карточки инструкции, которое пользователь увидит при про смотре списка"
          detailPath={resultPicturePath}
        />
      </div>
    </div>
  );
};

export default StepInstruction;
