import API from 'api/restApiService';

class UtilsApi {
  postUploadFile = (payload) => API.post('/api/files', payload);

  getCategories = () => API.get('/api/instructions/categories');

  getFinancingTypes = () => API.get('/api/instructions/finishing-types');

  getComplexity = () => API.get('/api/instructions/complexity-implementation');

  getStatuses = () => API.get('/api/instructions/finishing-types'); // url для статусов 

  deleteFile = (payload) => API.delete('/api/files', payload);

  getCountries = () => API.get('/api/geo/countries');

  getRegions = (id) =>
    API.get('/api/geo/regions', { countryId: id });
}

export default new UtilsApi();
