import API from 'api/restApiService';

class InstructionsAPI {
  getInstructionsList = () => API.get('/api/instructions-admin');

  getInstructionDetail = (payload) => API.get(`/api/instructions-admin/${payload}`);

  createInstruction = (payload) => API.post('/api/instructions-admin', payload);

  updateInstruction = ({ id, data }) => API.put(`/api/instructions-admin/${id}`, data);

  deleteInstruction = (payload) => API.delete(`/api/instructions-admin/${payload}`);
}

export default new InstructionsAPI();
