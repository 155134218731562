import { INFO_GAMES_ACTION_TYPES } from 'modules/GameDetail/redux/reducers/infoGames';

export const infoStartAC = () => ({ type: INFO_GAMES_ACTION_TYPES.GET.START });
export const infoSuccessAC = () => ({ type: INFO_GAMES_ACTION_TYPES.GET.SUCCESS });
export const infoErrorAC = (payload) => ({
  type: INFO_GAMES_ACTION_TYPES.GET.ERROR,
  payload
});

export const infoSetStatusesAC = (payload) => ({
  type: INFO_GAMES_ACTION_TYPES.SET_STATUSES,
  payload
});

