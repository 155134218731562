import HOR from 'redux/HOR';
import { ActionTypes } from 'redux/utils/actionCreator';

const { pipeHigherOrderReducers, withLoadable, withResetState } = HOR;

export const APPLICATIONS_ACTION_TYPES = new ActionTypes('APPLICATIONS', [
    'SET_APPLICATIONS',
    'START_DELETE',
    'START_CHANGE_STATUS'
])
    .listAT()
    .getActionTypes();



const initialState = {
    list: [],
    totalAmount: 0
};

const handleAction = {
    [APPLICATIONS_ACTION_TYPES.SET_APPLICATIONS]: (state, params) => ({
        ...state,
        ...params
    })
};

const reducer = (state = initialState, action) =>
    handleAction[action.type] ? handleAction[action.type](state, action.payload) : state;

export default pipeHigherOrderReducers(
    withResetState(APPLICATIONS_ACTION_TYPES.RESET_STATE, initialState),
    withLoadable({
        isLoadingActionType: [
            APPLICATIONS_ACTION_TYPES.GET.START,
            APPLICATIONS_ACTION_TYPES.START_DELETE,
            APPLICATIONS_ACTION_TYPES.START_CHANGE_STATUS
        ],
        successActionType: [APPLICATIONS_ACTION_TYPES.GET.SUCCESS],
        errorActionType: [APPLICATIONS_ACTION_TYPES.GET.ERROR]
    })
)(reducer);