import React from 'react';
import { Divider } from 'antd';
import TextEdditor from 'components/forms/textEdditor';
import UploadImg from 'components/forms/uploadImg';
import { useFormikContext } from 'formik';
import s from './index.module.scss';

const LimitsInstruction = () => {
  const { values } = useFormikContext();

  const {
    limitationDescription,
    precautionaryMeasuresDescription,
    setOfRules,
    precautionaryMeasuresPicturePath
  } = values.instructionLimitations;

  return (
    <div className={s.limits}>

      <Divider orientation="left">Меры предосторожности</Divider>

      <div className={s.warning}>
        <TextEdditor
          className={s.textarea}
          name="instructionLimitations.precautionaryMeasuresDescription"
          label="Описание"
          detailValue={precautionaryMeasuresDescription}
        />

        <UploadImg
          name="instructionLimitations.precautionaryMeasuresPicturePath"
          label="Обложка"
          descToolTip="Добавьте изображение для карточки инструкции, которое пользователь увидит при про смотре списка"
          detailPath={precautionaryMeasuresPicturePath}
        />
      </div>

      <div className={s.wrap}>
        <div className={s.wrapBlock}>
          <Divider orientation="left">Ограничения</Divider>

          <TextEdditor
            className={s.textarea}
            name="instructionLimitations.limitationDescription"
            label="Описание"
            descToolTip="Опишите, в каких случаях реализация инструкции будет невозможна"
            detailValue={limitationDescription}
          />
        </div>

        <div className={s.wrapBlock}>
          <Divider orientation="left">Свод правил</Divider>

          <TextEdditor
            className={s.textarea}
            name="instructionLimitations.setOfRules"
            label="Описание"
            detailValue={setOfRules}
          />
        </div>
      </div>





    </div>
  );
};

export default LimitsInstruction;
