import { INSTRUCTIONS_ACTION_TYPES } from 'modules/instructions/redux/reducers/instructions';

export const instructionStartAC = () => {
  console.log(INSTRUCTIONS_ACTION_TYPES)
  return { type: INSTRUCTIONS_ACTION_TYPES.GET.START }
};
export const instructionSuccessAC = () => ({ type: INSTRUCTIONS_ACTION_TYPES.GET.SUCCESS });
export const instructionsErrorAC = (payload) => ({
  type: INSTRUCTIONS_ACTION_TYPES.GET.ERROR,
  payload
});
export const instructionsSetListAC = (payload) => ({
  type: INSTRUCTIONS_ACTION_TYPES.SET_INSTRUCTIONS,
  payload
});
export const instructionStartDeleteAC = (payload) => ({
  type: INSTRUCTIONS_ACTION_TYPES.START_DELETE,
  payload
});
