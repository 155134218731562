/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

const BreadCrumbs = ({ list }) => (
  <Breadcrumb separator=">" style={{ marginBottom: 30 }}>
    {list.map(({ title, path }, index) => (
      <Breadcrumb.Item key={`bread-crumbs__${index}`}>
        <Link to={path || '/'}>{title}</Link>
      </Breadcrumb.Item>
    ))}
  </Breadcrumb>
);

export default BreadCrumbs;
