import React from 'react';
import moment from 'moment';
import { DatePicker, Typography } from 'antd';
import { Field } from 'formik';
import s from './index.module.scss';

const { Text } = Typography;

const DatePickerCustom = ({ name, label = '', detailValue, placeholder, isShowTime }) => {
  const dateValue = (form) => (form.values[name] || detailValue) * 1000;

  return (
    <Field>
      {({ form, meta, field }) => {
        const handleChange = (date) => form.setFieldValue(name, date?.unix());
        const isError = !!(meta.error && meta.touched);

        return (
          <div className={s.field}>
            {label && (
              <label htmlFor={name} className={s.label}>
                <Text type="secondary">{label}</Text>
              </label>
            )}
            <DatePicker
              {...field}
              onChange={handleChange}
              style={{ width: '100%' }}
              value={dateValue(form) && moment(dateValue(form))}
              placeholder={placeholder}
              showTime={isShowTime}
            />

            {isError && (
              <label htmlFor={name}>
                <Text type="danger">{meta.error?.value}</Text>
              </label>
            )}
          </div>
        );
      }}
    </Field>
  );
};

export default DatePickerCustom;
