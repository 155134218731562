import React from 'react';
import { Divider, Typography } from 'antd';
import InputCustom from 'components/forms/Input';
import TextEdditor from 'components/forms/textEdditor';
import UploadImg from 'components/forms/uploadImg';
import { useSelector } from 'react-redux';
import SelectCustom from 'components/forms/Select';
import { transfromData } from 'utils/transformData';
import {
  getCategories,
  getComplexity,
  getFinancingTypes
} from 'modules/instructionDetail/redux/selectors';
import { useFormikContext } from 'formik';
import s from './index.module.scss';

const { Title } = Typography;

const GeneralInfoInstruction = () => {
  const { values } = useFormikContext();
  const categoriesData = useSelector(getCategories);
  const financingData = useSelector(getFinancingTypes);
  const complexityData = useSelector(getComplexity);

  console.log('values-from-instructions', values);

  return (
    <div>
      <Title level={4}>Основная информация</Title>

      <Divider orientation="left">Инструкция</Divider>

      <div className={s.wrap}>
        <div className={s.inputs}>
          <InputCustom
            name="instructionBasicInformation.title"
            label="Название инструкции"
            descToolTip="Впишите полное название инструкции"
            placeholder="Введите название"
          />

          <TextEdditor
            className={s.textInput}
            name="instructionBasicInformation.description"
            label="Описание"
            detailValue={values.instructionBasicInformation.description}
            descToolTip="Должно включать описание идеи и ответы на вопросы: Почему проект востребован? Почему его нужно поддержать?,Какие положительные эффекты можно от этого получить
              (можно вставлять иллюстрации в тексте) "
          />

          <SelectCustom
            mode="tags"
            name="instructionBasicInformation.instructionCategoriesId"
            placeholder="Выберите категорию"
            label="Выберите категорию"
            descTooltip="Выберите наиболее подходящие категории для упрощения поиска"
            list={transfromData(categoriesData || [])}
            detailValue={values.instructionBasicInformation.instructionCategoriesId}
          />

          <SelectCustom
            name="instructionBasicInformation.instructionFinishingTypeId"
            label="Тип финансирования"
            placeholder="Выберите тип финансирования"
            list={transfromData(financingData || [])}
            detailValue={values.instructionBasicInformation.instructionFinishingTypeId}
          />
        </div>

        <UploadImg
          name="instructionBasicInformation.picturePath"
          label="Обложка"
          descToolTip="Добавьте изображение для карточки инструкции, которое пользователь увидит при про смотре списка"
          detailPath={values.instructionBasicInformation.picturePath}
        />
      </div>

      <Divider orientation="left">Показатели</Divider>

      <div className={s.indicators}>
        <InputCustom
          name="instructionBasicInformation.deadlines"
          label="Сроки"
          descToolTip="Срок реализации"
          placeholder="Введите срок реализации"
          className={s.input}
        />
        <InputCustom
          name="instructionBasicInformation.implementationCost"
          label="Стоимость"
          descToolTip="Стоимость реализации"
          placeholder="Введите стоимость"
          className={s.input}
        />
        <SelectCustom
          className={s.input}
          name="instructionBasicInformation.instructionComplexityImplementationId"
          label="Сложность"
          descTooltip="Оцените сложность реализации инструкции для обычных жителей (Легко / Нормально / Сложно)"
          list={transfromData(complexityData || [])}
          detailValue={values.instructionBasicInformation.instructionComplexityImplementationId}
          placeholder="Выберите сложность"
        />
      </div>
    </div>
  );
};

export default GeneralInfoInstruction;
