/* eslint-disable arrow-body-style */
import HOR from 'redux/HOR';
import { ActionTypes } from 'redux/utils/actionCreator';

const { pipeHigherOrderReducers, withLoadable, withResetState } = HOR;

export const INFO_INSTRUCTIONS_ACTION_TYPES = new ActionTypes('INFO_INSTRUCTIONS', [
  'SET_CATEGORIES',
  'SET_FINANCING_TYPES',
  'SET_COMPLEXITY'
])
  .listAT()
  .getActionTypes();

const initialState = {
  categories: [],
  financingTypes: [],
  complexity: []
};

const handleAction = {
  [INFO_INSTRUCTIONS_ACTION_TYPES.SET_CATEGORIES]: (state, params) => ({
    ...state,
    categories: params
  }),
  [INFO_INSTRUCTIONS_ACTION_TYPES.SET_FINANCING_TYPES]: (state, params) => ({
    ...state,
    financingTypes: params
  }),
  [INFO_INSTRUCTIONS_ACTION_TYPES.SET_COMPLEXITY]: (state, params) => ({
    ...state,
    complexity: params
  })
};

const reducer = (state = initialState, action) => {
  return handleAction[action.type] ? handleAction[action.type](state, action.payload) : state;
};

export default pipeHigherOrderReducers(
  withResetState(INFO_INSTRUCTIONS_ACTION_TYPES.RESET_STATE, initialState),
  withLoadable({
    isLoadingActionType: [INFO_INSTRUCTIONS_ACTION_TYPES.GET.START],
    successActionType: [INFO_INSTRUCTIONS_ACTION_TYPES.GET.SUCCESS],
    errorActionType: [INFO_INSTRUCTIONS_ACTION_TYPES.GET.ERROR]
  })
)(reducer);
