import React from 'react';
import { Divider, Typography } from 'antd';
import TextEdditor from 'components/forms/textEdditor';
import UploadImg from 'components/forms/uploadImg';
import { useFormikContext } from 'formik';
import s from './index.module.scss';

const { Title } = Typography;

const DescriptionInstruction = () => {
  const { values } = useFormikContext();

  const desc = values.instructionDescription;

  return (
    <div className={s.desc}>
      <Title className={s.descProblem} level={4}>
        Описание
      </Title>

      <Divider orientation="left">Проблема</Divider>

      <TextEdditor
        className={s.textarea}
        name="instructionDescription.problemDescription"
        label="Описание"
        detailValue={desc.problemDescription}
      />

      <Divider orientation="left">Решение</Divider>

      <div className={s.solutions}>
        <TextEdditor
          className={s.textarea}
          name="instructionDescription.solutionDescription"
          label="Описание"
          detailValue={desc.solutionDescription}
        />
        <UploadImg
          name="instructionDescription.solutionPicturePath"
          label="Обложка"
          descToolTip="Добавьте изображение для карточки инструкции, которое пользователь увидит при про смотре списка"
          detailPath={desc.solutionPicturePath}
        />
      </div>

      <Divider orientation="left">Аналитика</Divider>

      <div className={s.analitics}>
        <TextEdditor
          className={s.textarea}
          name="instructionDescription.analyticsDescription"
          label="Описание"
          detailValue={desc.analyticsDescription}
        />
        <UploadImg
          name="instructionDescription.analyticsPicturePath"
          label="Обложка"
          descToolTip="Добавьте изображение для карточки инструкции, которое пользователь увидит при про смотре списка"
          detailPath={desc.analyticsPicturePath}
        />
      </div>
    </div>
  );
};

export default DescriptionInstruction;
