import { call, put, takeEvery } from 'redux-saga/effects';
import SERVICE_API from 'api';
import { APPLICATION_DETAILS_ACTION_TYPES } from 'modules/ApplicatonDetails/redux/reducers/applicationDetailsReducers';
import {
  createSuccessApplicationDetailsAC,
  applicationDetailsErrorAC,
  applicationDetailsSetDataAC,
  applicationDetailsSuccessAC
} from 'modules/ApplicatonDetails/redux/actions/applicationDetailsActions';
import { transformProjectApplicatiomData } from 'utils/transformData';


function* getApplicationDetailsData({ payload }) {
  try {
    // const { data } = yield call(SERVICE_API.ApplicationsAPI.getApplicationDetail, payload);
    const dataFromBack = yield call(SERVICE_API.ApplicationsAPI.getApplicationDetail, payload);
    yield put(applicationDetailsSetDataAC(transformProjectApplicatiomData(dataFromBack.data)));
    yield put(applicationDetailsSuccessAC());
  } catch ({ response }) {
    yield put(applicationDetailsErrorAC(response.data));
  }
}

function* createProjectApplication({ payload }) {
  try {

    yield call(SERVICE_API.ApplicationsAPI.createApplication, payload);
    yield put(createSuccessApplicationDetailsAC());
  } catch ({ response }) {
    yield put(applicationDetailsErrorAC(response.data));
  }
}
function* updateProjectApplication({ payload }) {
  try {
    yield call(SERVICE_API.ApplicationsAPI.updateApplication, payload);
    yield put(createSuccessApplicationDetailsAC());
  } catch ({ response }) {
    yield put(applicationDetailsErrorAC(response.data));
  }
}

export function* projectApplicationDetailsSaga() {
  yield takeEvery(APPLICATION_DETAILS_ACTION_TYPES.GET.START, getApplicationDetailsData);
  yield takeEvery(APPLICATION_DETAILS_ACTION_TYPES.START_CREATE, createProjectApplication);
  yield takeEvery(APPLICATION_DETAILS_ACTION_TYPES.START_UPDATE, updateProjectApplication);
}
