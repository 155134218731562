import React from 'react';
import PartOne from './partOne';
import PartTwo from './partTwo';
import PartThree from './partThree';

import s from './index.module.scss';

const InstructionDetailForm = () => (
  <div className={s.content}>
    <PartOne />
    <PartTwo />
    <PartThree />
  </div>
);

export default InstructionDetailForm;
