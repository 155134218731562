/* eslint-disable arrow-body-style */
import HOR from 'redux/HOR';
import { ActionTypes } from 'redux/utils/actionCreator';

const { pipeHigherOrderReducers, withLoadable, withResetState } = HOR;

export const INFO_APPLICATIONS_ACTION_TYPES = new ActionTypes('APPLICATION', ['SET_CATEGORIES', 'SET_FINANCING_TYPES', 'SET_COMPLEXITY'])
  .listAT()
  .getActionTypes();

const initialState = {
  categories: [],
  financingTypes: [],
  complexity: []
};

const handleAction = {
  [INFO_APPLICATIONS_ACTION_TYPES.SET_CATEGORIES]: (state, params) => {

    return {
      ...state,
      categories: params
    }
  },
  [INFO_APPLICATIONS_ACTION_TYPES.SET_FINANCING_TYPES]: (state, params) => ({
    ...state,
    financingTypes: params
  }),
  [INFO_APPLICATIONS_ACTION_TYPES.SET_COMPLEXITY]: (state, params) => ({
    ...state,
    complexity: params
  }),
};

const reducer = (state = initialState, action) =>
  handleAction[action.type] ? handleAction[action.type](state, action.payload) : state;

export default pipeHigherOrderReducers(
  withResetState(INFO_APPLICATIONS_ACTION_TYPES.RESET_STATE, initialState),
  withLoadable({
    isLoadingActionType: [INFO_APPLICATIONS_ACTION_TYPES.GET.START],
    successActionType: [INFO_APPLICATIONS_ACTION_TYPES.GET.SUCCESS],
    errorActionType: [INFO_APPLICATIONS_ACTION_TYPES.GET.ERROR]
  })
)(reducer);
