/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Layout, Menu, Button } from 'antd';
import { NavLink, Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { platformRoutes, gameRoutes, applicationRoutes } from 'constans/MENU_ROUTES';
import s from 'components/menu/index.module.scss';
import { getisPlatform, getActiveButton } from '../../redux/selectors';
import { setIsPlatform, setActiveButton } from '../../redux/actions/menu';

const { Sider } = Layout;
const menuBtn = [
  {
    title: 'Игра',
    isPlatform: false,
    path: '/games'
  },
  {
    title: 'Платформа',
    isPlatform: true,
    path: '/'
  },
  {
    title: 'Проекты',
    isPlatform: false,
    path: '/projects'
  }
];

const MyMenu = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [collapse, setCollapse] = useState(false);
  const isPlatform = useSelector(getisPlatform);
  const activeButton = useSelector(getActiveButton);

  useEffect(() => {


    if (pathname === '/games') {
      dispatch(setIsPlatform(false));
      dispatch(setActiveButton(0));
    }
    if (pathname === '/') {
      dispatch(setIsPlatform(true));
      dispatch(setActiveButton(1));
    }
    if (pathname === '/projects') {
      dispatch(setIsPlatform(false));
      dispatch(setActiveButton(2));
    }
  }, [pathname]);



  const activeRoute = activeButton === 0 ? platformRoutes : activeButton === 1 ? gameRoutes : applicationRoutes;

  const toggle = () => setCollapse((collapsed) => !collapsed);

  const handleClick = (isPlatformRoute, index) => {
    dispatch(setIsPlatform(isPlatformRoute));
    dispatch(setActiveButton(index));
  };





  return (
    <Sider
      width={208}
      theme="light"
      className={s.sider}
      trigger={null}
      collapsible
      collapsed={collapse}
    >
      <Menu mode="vertical" className={s.menu}>
        <div className={s.buttons}>
          {menuBtn.map((button, index) => (
            <Button
              onClick={() => handleClick(button.isPlatform, index)}
              type={`${activeButton === index ? 'primary' : 'default'}`}
            >
              <Link to={button.path}>{button.title}</Link>
            </Button>
          ))}
        </div>

        {activeRoute.map((link) => (
          <NavLink to={link.route} key={`menu__${link.route}`}>
            <Menu.Item className={s.menu__item}>{link.title}</Menu.Item>
          </NavLink>
        ))}
      </Menu>
      <div className={s.trigger__wrap}>
        {collapse ? (
          <MenuUnfoldOutlined className="trigger" onClick={toggle} />
        ) : (
          <MenuFoldOutlined className="trigger" onClick={toggle} />
        )}
      </div>
    </Sider>
  );
};

export default MyMenu;
