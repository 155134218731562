import React from 'react';
import { Button } from 'antd';
import Styles from 'components/header/index.module.scss';
import { LogoutOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';
import { SERVICES_URLS } from 'constans/SERVICES_URLS';

const ExitButton = () => {
  const handleExit = () => {
    Cookies.remove('token', { path: '/' });
    window.location.href = SERVICES_URLS.auth;
  };

  return (
    <Button className={Styles.logout} onClick={handleExit}>
      <span>Выйти</span>
      <LogoutOutlined />
    </Button>
  );
};

export default ExitButton;
