import {
  instructionsErrorAC,
  instructionsSetListAC,
  instructionSuccessAC
} from 'modules/instructions/redux/actions/actionsInstructions';
import { call, put, takeEvery } from 'redux-saga/effects';
import SERVICE_API from 'api';
import { INSTRUCTIONS_ACTION_TYPES } from 'modules/instructions/redux/reducers/instructions';

function* getInstructionsList() {
  try {
    const { data } = yield call(SERVICE_API.InstructionsAPI.getInstructionsList);

    console.log('instructions list-from-back===>', data);
    yield put(instructionsSetListAC(data));
    yield put(instructionSuccessAC());
  } catch ({ response }) {
    yield put(instructionsErrorAC(response.data));
  }
}

function* deleteInstruction({ payload }) {
  try {
    yield call(SERVICE_API.InstructionsAPI.deleteInstruction, payload);
    const { data } = yield call(SERVICE_API.InstructionsAPI.getInstructionsList);
    yield put(instructionsSetListAC(data));
    yield put(instructionSuccessAC());
  } catch ({ response }) {
    yield put(instructionsErrorAC(response.data));
  }
}

export function* instructionsListSaga() {
  yield takeEvery(INSTRUCTIONS_ACTION_TYPES.GET.START, getInstructionsList);
  yield takeEvery(INSTRUCTIONS_ACTION_TYPES.START_DELETE, deleteInstruction);
}
