/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useAuth } from 'hooks/useAuth.hook';
import { useDispatch, useSelector } from 'react-redux';
import { GetUserAction, ResetAction } from 'redux/actions/user';
import AuthLink from 'components/router/authLink';
import { Route } from 'react-router';
import { getUserRole } from 'redux/selectors';

const PrivateRoute = (props) => {
  const { token } = useAuth();
  const dispatch = useDispatch();
  const userRole = useSelector(getUserRole);

  useEffect(() => {
    dispatch(GetUserAction());

    return () => {
      dispatch(ResetAction());
    };
  }, []);

  const isUser = userRole === 'user';

  if (token) return <Route {...props} />;

  return <AuthLink />;
};

export default React.memo(PrivateRoute);
