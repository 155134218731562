import React from 'react';
import GeneralInfoGame from './generalInfoGame';
import s from './index.module.scss';

const GameDetailForm = () => (
  <div className={s.content}>
    <GeneralInfoGame />
  </div>
);

export default GameDetailForm;
