import React, { useEffect } from 'react';
import { Button, Layout, Spin, Table } from 'antd';
import { projectApplicationColumns } from 'constants/TABLE_COLUMNS';
import { useDispatch, useSelector } from 'react-redux';
import {
  applicationStartAC,
  applicationStartDeleteAC,
  applicationStartChangeStatus
} from 'modules/ProjectApplications/redux/actions/projectApplicatonActions';

import {
  getApplicationsData,
  getIsLoading
} from 'modules/ProjectApplications/redux/projectApplicationsSelector';
import { applicationTransformDataTable } from 'utils/transformData';
import { Link } from 'react-router-dom';
import { fetchFormInfoStart } from 'modules/ApplicatonDetails/redux/actions/formInfoActions';
import s from './content.module.scss';

const { Content } = Layout;

const ProjectApplicationsContent = () => {
  const dispatch = useDispatch();
  const dataTable = useSelector(getApplicationsData);
  const isLoading = useSelector(getIsLoading);
  console.log('dataTable', dataTable);

  useEffect(() => {
    dispatch(fetchFormInfoStart());
    dispatch(applicationStartAC());
  }, []);

  const handleDelete = (id, option) => {
    const actualId = id.split('/')[0];
    if (!!id && option.children === 'Удалить') {
      dispatch(applicationStartDeleteAC(actualId));
    }
    if (!!id && option.children === 'Изменить статус') {
      dispatch(applicationStartChangeStatus(actualId));
    }
  };

  return (
    <Content className={s.content}>
      <Spin spinning={isLoading}>
        <div className={s.block}>
          <h1 className={s.title}>Список проектов</h1>
          <Button type="primary">
            <Link to={'/application-create'}>Добавить заявку</Link>
          </Button>
        </div>
        <div className={s.tableWrap}>
          <Table
            pagination={{
              position: ['bottomLeft'],
              pageSize: 50
            }}
            columns={projectApplicationColumns(handleDelete)}
            dataSource={applicationTransformDataTable(dataTable)}
          />
        </div>
      </Spin>
    </Content>
  );
};

export default ProjectApplicationsContent;
